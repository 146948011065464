import React from "react";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
import ReactTable from "react-table";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const BookingsTable = ({data, columnsToShow, columnsToHide}) => {
    const columns = [
            {
                Header: "Doctor",
                accessor: "practitioner_name"
            },
            {
                Header: "Patient",
                accessor: "patient_name",
                Cell: row => (
                  <div>
                      <p style={styles.patientName}>{row.original.patient_name}</p>
                      <p style={styles.patientDetail}>{row.original.patient_email}</p>
                  </div>
                )
            },
            {
                Header: "Contact Number",
                accessor: "patient_contact_number"
            },
            {
                Header: "Patient Number",
                accessor: "patient_number"
            },
            {
                Header: "Date/Time",
                accessor: "date_time"
            },
            {
                Header: "Payment Status",
                accessor: "payment_status"
            },
            {
                Header: "",
                accessor: "booking_type",
                filterable: false,
                width: 50,
                resizable: false,
                Cell: row => (
                  <div>
                      {row.original.booking_type === "individual consult" ? <Person/> : <Group/>}
                  </div>
                )
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
                // width: 150,
                resizable: false
            }
        ];

    const filteredColumns = columns.filter((columnItem) => {
        if (!columnsToShow)
            return true
        return columnsToShow.indexOf(columnItem.Header) > -1
    }).filter((columnItem) => {
        if (!columnsToHide)
            return true
        return columnsToHide.indexOf(columnItem.Header) === -1
    })

    return (
      <ReactTable
        data={data}
        filterable
        defaultFilterMethod={(filter, row) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
        }}
        defaultSorted={[{ id: "date_time", desc: true }]}
        columns={filteredColumns}
        defaultPageSize={data.length > 5 ? 10 : 5}
        showPaginationBottom={true}
        className="-striped -highlight"
      />
    );
};

export default BookingsTable;
