import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BankDetailsForm from "./BankDetailsForm";
import { fetchPractitionerBankAccounts, fetchPractitionerPractices } from "../../redux/actions/telehealth";

const BankDetails = () => {

    const dispatch = useDispatch();
    const practitionerPractices = useSelector(state => state.telehealth.practitionerPractices);
    const [showBankForm, setShowBankForm] = useState(false);
    const networkActions = useSelector(state => state.settings.networkRequests);

    const isNetworkLoading = (keysToCheck) => {
        return keysToCheck.some((key) => {
            return !!networkActions[key]
        })
    };

    useEffect(() => {
        let result = practitionerPractices.some((practice) => {
            return practice.online_billing_available
        })
        setShowBankForm(result);
    }, [practitionerPractices])

    useEffect(()=>{
        if (showBankForm){
            dispatch(fetchPractitionerBankAccounts())
        }
    },[showBankForm])

    if (showBankForm)
        return <BankDetailsForm/>

    if (isNetworkLoading(['saveBankAccount', 'getPractitionerBankAccounts', 'getPractitionerPractices']))
        return (
          <div>
              Loading...
          </div>
        );
    return (
      <div>
          You do not belong to any practices that have Online Billing available
      </div>
    );
};

export default BankDetails;
