import {
    container,
    cardTitle,
    whiteColor,
    grayColor,
    blackColor,
    primaryColor

} from "assets/jss/material-dashboard-pro-react.js";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const loginPageStyle = theme => ({
    ...buttonStyle,
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        },
        color: blackColor
    },
    cardTitle: {
        ...cardTitle,
        color: whiteColor
    },
    textCenter: {
        textAlign: "center"
    },
    forgotPassword: {
        paddingTop: 5,
        cursor: 'pointer',
        color: grayColor[0]
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: whiteColor
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: grayColor[6]
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: 15
    },
    logoBold: {
        fontWeight: 400
    },
    termsText: {
        cursor: "pointer",
        color: primaryColor[0],
        fontWeight: "bold"
    }
});

export default loginPageStyle;
