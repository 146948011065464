import { createTheme } from "@mui/material/styles";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

export const scheduleHours = {
    '00:00': {time: '00:00', index: 0},
    '00:30': {time: '00:30', index: 1},
    '01:00': {time: '01:00', index: 2},
    '01:30': {time: '01:30', index: 3},
    '02:00': {time: '02:00', index: 4},
    '02:30': {time: '02:30', index: 5},
    '03:00': {time: '03:00', index: 6},
    '03:30': {time: '03:30', index: 7},
    '04:00': {time: '04:00', index: 8},
    '04:30': {time: '04:30', index: 9},
    '05:00': {time: '05:00', index: 10},
    '05:30': {time: '05:30', index: 11},
    '06:00': {time: '06:00', index: 12},
    '06:30': {time: '06:30', index: 13},
    '07:00': {time: '07:00', index: 14},
    '07:30': {time: '07:30', index: 15},
    '08:00': {time: '08:00', index: 16},
    '08:30': {time: '08:30', index: 17},
    '09:00': {time: '09:00', index: 18},
    '09:30': {time: '09:30', index: 19},
    '10:00': {time: '10:00', index: 20},
    '10:30': {time: '10:30', index: 21},
    '11:00': {time: '11:00', index: 22},
    '11:30': {time: '11:30', index: 23},
    '12:00': {time: '12:00', index: 24},
    '12:30': {time: '12:30', index: 25},
    '13:00': {time: '13:00', index: 26},
    '13:30': {time: '13:30', index: 27},
    '14:00': {time: '14:00', index: 28},
    '14:30': {time: '14:30', index: 29},
    '15:00': {time: '15:00', index: 30},
    '15:30': {time: '15:30', index: 31},
    '16:00': {time: '16:00', index: 32},
    '16:30': {time: '16:30', index: 33},
    '17:00': {time: '17:00', index: 34},
    '17:30': {time: '17:30', index: 35},
    '18:00': {time: '18:00', index: 36},
    '18:30': {time: '18:30', index: 37},
    '19:00': {time: '19:00', index: 38},
    '19:30': {time: '19:30', index: 39},
    '20:00': {time: '20:00', index: 40},
    '20:30': {time: '20:30', index: 41},
    '21:00': {time: '21:00', index: 42},
    '21:30': {time: '21:30', index: 43},
    '22:00': {time: '22:00', index: 44},
    '22:30': {time: '22:30', index: 45},
    '23:00': {time: '23:00', index: 46},
    '23:30': {time: '23:30', index: 47},
}

export const workingHoursStyles = {

    menuPaper: {
        maxHeight: '200px'
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    action: {
        display: "flex",
        alignItems: "center"
    },
    actionText: {
        paddingLeft: 15
    },
    patientName: {
        margin: 0
    },
    patientDetail: {
        margin: 0,
        fontSize: "0.8em"
    },
    panel: {
        padding: '0 30px',
        '&.right': {
            display: 'flex',
            flexFlow: 'column',
            borderLeft: '1px solid #ddd',
            padding: '0 30px',        
        },
    },
    gridItem: {
        display: 'grid',
        minHeight: '87px',
        gridTemplateColumns: '42px 75px 300px auto 48px',
        columnGap: '20px',
        padding: '15px',
        width: '100%',
        alignItems: 'flex-start',
        borderTop: '1px solid #ddd',
        '&:nth-child(3)': {
            border: 'none',
        }
    },
    panelTitle: {
        fontSize: 'large',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    panelDescription: {
        fontSize: 'larger',
        marginBottom: '20px',
    },

    timeInput: {
        fontSize: 'larger'
    },
    timeSelection: {
        display: 'grid',
        gridTemplateColumns: 'auto auto 56px',
        columnGap: '10px',
    },
    timeColumn: {
        display: 'grid',
        rowGap: '10px',
    },
    padded: {
        fontWeight: 'bold',
        padding: '9px',
        textTransform: 'uppercase',
        whiteSpace: 'pre',
        '&.faded': {
            color: '#aaa'
        }
    },
    doubleColumn: {
        display: 'grid',
        gridTemplateColumns: 'minmax(635px,auto) minmax(450px,auto)',
    },
    panelButton: {
        fontSize: 'larger',
        fontWeight: 'bold',
        borderRadius: '5px',
        transition: '100ms',  
        '&.outlined': {
            color: '#e91e63',
            background: 'white',
            border: '2px solid #e91e63',
            '&:hover': {
                color: 'white',
                background: '#e91e63',
            }, 
        },        
        '&.anchorBottom:last-of-type':{
            alignSelf: 'end',
            marginTop: 'auto'
        },
    },
    overrideItem: {
        display: 'grid',
        gridTemplateColumns: 'minmax(120px,auto) minmax(75px,auto) minmax(75px,auto) 48px',
        columnGap: '20px',
        padding: '15px 5px',
        width: '100%',
        alignItems: 'center',
        borderTop: '1px solid #ddd',
        '&:nth-child(4)': {
            border: 'none',
        },
        '&:last-of-type': {
            borderBottom: '1px solid #ddd',
        },
        // '& .hoverShow': {
        //     opacity: 0,
        //     transition: '200ms',            
        // },
        // '&:hover': {
        //     '& .hoverShow': {
        //         opacity: 1
        //     }
        // }
    },
    // hidden: {
    //     opacity: '0',
    //     boxShadow: 'none'
    // },
    slider: {
        margin: '0 2em 2em',
        maxWidth: 'calc(100% - 4em)',
    },
    sliderAnnotationLabel: {
        fontSize: 'small',
        color: '#bbb'
    },
    sliderAnnotationValue: {
        fontSize: 'medium'
    },
    sliderAnnotations: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 2em',
        fontWeight: 'bold',
        '& .end': {
            textAlign: 'end'
        }
    },
    popupContainer: {
        padding: '2.5em 1.5em',
        display: 'flex',
        flexDirection: 'column',
        '&.info': {
            alignItems: 'center',
            rowGap: '20px'
        }
    },
    // errorPopupContainer: {
    //     padding: '2.5em 1.5em',
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    popupTitle: {
        fontSize: '1.25em',
        maxWidth: '310px',
        textAlign: 'center',
        fontWeight: 'bold',
        '&.clash': {
            maxWidth: 'unset',
            fontSize: '2em',
            color: '#e91e63'
        }
    },
    popupMessage: {
        fontSize: '1.3em',
        textAlign: 'center',
    },
    popupIcon: {
        // fontSize: '1.25em',
        width: '100px',
        height: '100px',
        '& svg': {
            width: '100%',
            height: '100%',
            color: '#e91e63'
        }
        // textAlign: 'center',
        // fontWeight: 'bold'
    },
    popupButtonRow: {
        padding: '0 1.5em',
        fontSize: '1.25em',
        columnGap: '10px',
        display: 'flex',
        justifyContent: 'space-around'
    },
    popupButton: {
        minWidth: '120px',
        fontSize: 'medium',
        padding: '0.25em 1.25em',
        color: 'white',
        background: '#e91e63',
        boxShadow: 'none',
        fontWeight: 'bold',
        borderRadius: '5px',
        border: '2px solid #e91e63',
        '&:hover': {
            borderColor: '#b9124a',
            background: '#b9124a',
            boxShadow: 'none'
        },
        '&.outlined': {
            color: '#e91e63',
            background: 'white',
            '&:hover': {
                color: 'white',
                background: '#e91e63',
                borderColor: '#e91e63',
                boxShadow: 'none'
            },
        },
        // TODO: Fix padding in popup container rather than individual elements
    },
    ...buttonStyle,

    timeSlot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'auto',
        fontSize: '16px',
        fontWeight: '400',
        padding: '15px 14px',
        color: '#666',
        background: '#fafafa',
        border: '1px solid #ccc',
        borderRadius: '4px',
        '& svg': {
            opacity: '0',
            transition: '300ms'
        },
        '&:hover': {
            '& svg': {
                transitionDelay: '500ms',
                opacity: '0.5'
            }
        }
    }
};

export const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#e91e63',
            }
        },
        MuiPickersDay: {
            day: {
              color: '#e91e63',
            },
            daySelected: {
              backgroundColor: '#e91e63',
              '&:hover': {
                backgroundColor: '#d81b60',
              }
            },
            current: {
              color: '#b9124a',
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: '#e91e63',
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: '#e91e63',
            },
            thumb: {
                borderColor: '#e91e63',
            },
            noPoint: {
                backgroundColor: '#e91e63',
            }
        },
        MuiSlider: {
            thumb: {
                backgroundColor: '#e91e63',
                height: '16px',
                width: '16px',
            },
            track: {
                backgroundColor: '#e91e63',
                height: '4px'
            },
            rail: {
                backgroundColor: '#e91e63',
                height: '4px'
            }
        }
    }
})