export const FETCH_PRACTICES = "FETCH_PRACTICES";
export const SET_PRACTITIONERS = "SET_PRACTITIONERS";
export const FETCH_PRACTITIONER_PRACTICES = "FETCH_PRACTITIONER_PRACTICES";
export const SET_PRACTITIONER_PRACTICES = "SET_PRACTITIONER_PRACTICES";
export const FETCH_PRACTITIONER_BANK_ACCOUNTS = "FETCH_PRACTITIONER_BANK_ACCOUNTS";
export const SET_PRACTITIONER_BANK_ACCOUNTS = "SET_PRACTITIONER_BANK_ACCOUNTS";

export const SET_CALENDAR_ALERT = "SET_CALENDAR_ALERT";
export const SET_WORKING_HOURS = 'SET_WORKING_HOURS';
export const FETCH_WORKING_HOURS = 'FETCH_WORKING_HOURS';
export const SAVE_WORKING_HOURS = 'SAVE_WORKING_HOURS';
export const SAVE_WORKING_HOURS_FAILED = 'SAVE_WORKING_HOURS_FAILED';
export const FETCH_PRACTITIONER_IS_CALENDAR_USER = 'FETCH_PRACTITIONER_IS_CALENDAR_USER';
export const SET_PRACTITIONER_IS_CALENDAR_USER = 'SET_PRACTITIONER_IS_CALENDAR_USER';
export const FETCH_BOOKINGS_FOR_OVERRIDE = 'FETCH_BOOKINGS_FOR_OVERRIDE';
export const SET_BOOKINGS_FOR_OVERRIDE = 'SET_BOOKINGS_FOR_OVERRIDE';
export const SET_CALENDAR_LOADING = 'SET_CALENDAR_LOADING';
export const SET_CALENDAR_OVERRIDE_LOADING = 'SET_CALENDAR_OVERRIDE_LOADING';
export const CLOSE_OVERRIDE_DATE_SELECTOR = 'CLOSE_OVERRIDE_DATE_SELECTOR';

export const fetchPractice = () => ({type: FETCH_PRACTICES});
export const setPractitioners = (p) => ({ type: SET_PRACTITIONERS, payload: p });
export const fetchPractitionerPractices = () => ({type: FETCH_PRACTITIONER_PRACTICES})
export const setPractitionerPractices = (p) => ({ type: SET_PRACTITIONER_PRACTICES, payload: p });
export const setPractitionerBankAccounts = (p) => ({ type: SET_PRACTITIONER_BANK_ACCOUNTS, payload: p });
export const fetchPractitionerBankAccounts = () => ({type: FETCH_PRACTITIONER_BANK_ACCOUNTS});

export const setCalendarAlert = (alert) => ({type: SET_CALENDAR_ALERT, payload: alert});
export const setWorkingHours = (p) => ({type: SET_WORKING_HOURS, payload: p});
export const fetchWorkingHours = () => ({type: FETCH_WORKING_HOURS});
export const saveWorkingHours = (p) => ({type: SAVE_WORKING_HOURS, payload: p});
export const saveWorkingHoursFailed = (p) => ({type: SAVE_WORKING_HOURS_FAILED, payload: p});
export const fetchPractitionerIsCalendarUser = () => ({type: FETCH_PRACTITIONER_IS_CALENDAR_USER});
export const setPractitionerIsCalendarUser = (p) => ({ type: SET_PRACTITIONER_IS_CALENDAR_USER, payload: p });
export const fetchBookingsForOverride = (p) => ({type: FETCH_BOOKINGS_FOR_OVERRIDE, payload: p});
export const setBookingsForOverride = (p) => ({type: SET_BOOKINGS_FOR_OVERRIDE, payload: p});
export const setCalendarLoading = (value) => ({type: SET_CALENDAR_LOADING, payload: value});
export const setCalendarOverrideLoading = (value) => ({type: SET_CALENDAR_OVERRIDE_LOADING, payload: value});
export const closeOverrideDateSelector = (value) => ({type: CLOSE_OVERRIDE_DATE_SELECTOR, payload: value});