import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    filePreviewListDiv: {
        display: "grid",
        height: "60px",
        marginBottom: "5px",
        gridTemplateColumns: "120px 10fr 5fr 1fr",
        width: "100%"
    },
    dropZone: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "1px solid gray",
        borderRadius: "4px",
        padding: "25px",
        minHeight: "100px"
    },
    removeIcon: {
        cursor: "pointer"
    },
    dragFileTextLarge: {
        fontSize: "2em",
        color: "gray"
    },
    dragFileTextMedium: {
        fontSize: "1.3em",
        color: "gray"
    },
    fileNameDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "lightgray",
        margin: "5px 0",
        paddingLeft: "5px"
    },
    linearProgressDiv: {
        alignItems: "center"
    },
    fileIcon: {
        fontSize: "5em"
    },
    pdfIcon: {
        color: "#ff0000"
    }

});