import React, { useEffect, useState } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";

// @material-ui/icons

import BankIcon from '@material-ui/icons/AccountBalance';
import SweetAlert from "react-bootstrap-sweetalert";
import HourglassLoader from "assets/img/hourglass.gif";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";

import { setAlert } from "../../redux/actions/bookings";

import {
    checkBankAccountDetails,
    clearBankDetailsForm,
    saveBankDetails,
    setBankDetailsFormField
} from "../../redux/actions/settings";
import { awaitingVerificationAlertMessage } from "../../constants/bank_details";
import useInterval from "../../hooks/useInterval";
import { fetchPractitionerBankAccounts } from "../../redux/actions/telehealth";

const useStyles = makeStyles(styles);
export default function ExtendedForms() {

    const dispatch = useDispatch();
    const alert = useSelector(state => state.bookings.alert);
    const formFields = useSelector(state => state.settings.bankDetailsFormFields);
    const practitionerPractices = useSelector(state => state.telehealth.practitionerPractices);
    const practitionerBankAccounts = useSelector(state => state.settings.practitionerBankAccounts);
    const networkActions = useSelector(state => state.settings.networkRequests);
    const [checkBankAccountVerified, setCheckBankAccountVerified] = useState(false);
    const banks = useSelector(state => state.settings.banks);
    const selectedPracticeBankAccount = useSelector(state => state.settings.selectedPracticeBankAccount);
    const delay = 3000;

    const selectedBankAccount = (practitionerBankAccounts || []).find((account) => {
        if (!formFields.selectedPractice)
            return false
        return account.practice_id === formFields.selectedPractice.id;
    })

    const isNetworkLoading = (keysToCheck) => {
        return keysToCheck.some((key) => {
            return !!networkActions[key]
        })
    };

    const isFilled = (text) => {
        return !!text && text.length > 0
    };

    const isNumeric = (value) => {
        let pattern = /^\d+$/;
        return pattern.test(value);
    }

    const isFormInvalid = () => {
        if (isNetworkLoading(['saveBankAccount']))
            return true
        for (const field in formFields) {
            if (!formFields[field])
                return true

            if (typeof formFields[field] === "string"){
                let fieldValid = isFilled(formFields[field]);
                if (!fieldValid)
                    return true
            }

            if (["accountNumber", "branchCode"].indexOf(field) > -1){
                if (!isNumeric(formFields[field]))
                    return true
            }
        }
        return false;
    };

    const isInvalid = isFormInvalid();

    useEffect(() => {
        if (checkBankAccountVerified && selectedPracticeBankAccount){
            if (selectedPracticeBankAccount.status === "invalid"){
                setCheckBankAccountVerified(false);
                dispatch(setAlert({
                    show: true,
                    type: "warning",
                    message: "The bank account details provided could not be verified! Please verify the details provided.",
                    title: "Account Not Valid!"
                }));
                return
            }
            if (selectedPracticeBankAccount.status === "verified"){
                setCheckBankAccountVerified(false);
                dispatch(setAlert({
                    show: true,
                    type: "success",
                    message: "The bank account details provided have been verified!",
                    title: "Verified!"
                }));
            }
        }
    }, [selectedPracticeBankAccount])

    React.useEffect(() => {
        dispatch(clearBankDetailsForm());
        return (()=>{
            dispatch(clearBankDetailsForm());
        })
    }, []);

    useEffect(() => {
        if (alert.show && alert.message === awaitingVerificationAlertMessage){
            if (!checkBankAccountVerified)
                setCheckBankAccountVerified(true)
            return
        }

        if (checkBankAccountVerified)
            setCheckBankAccountVerified(false)

    }, [alert])

    useInterval(() => {
        dispatch(checkBankAccountDetails(formFields["selectedPractice"]["id"]))
    }, checkBankAccountVerified ? delay : null);

    const practitionerSelectionFilterOptions = createFilterOptions({
        stringify: p => `${p.site.name} ${p.name}`
    });

    const titleCase = (str) => {
        return str.toLowerCase().split(' ').map(function(word) {
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    }

    const filteredPractices = () => {
        return practitionerPractices.filter(item => {
            return item.online_billing_available;
        })
    };

    const bankSelectionFilterOptions = createFilterOptions({
        stringify: p => `${p}`
    });

    const handlePracticeSelected = (event, newValue) => {
        dispatch(setBankDetailsFormField("selectedPractice", newValue));
    };

    const handlebankSelected = (event, newValue) => {
        dispatch(setBankDetailsFormField("selectedBank", newValue));
    };

    const classes = useStyles();

    const handleSubmit = () => {
        const payload = {
            bank: formFields["selectedBank"],
            account_name: formFields["accountName"],
            account_number: formFields["accountNumber"],
            branch_code: formFields["branchCode"],
            practice_id: formFields["selectedPractice"]["id"]
        };

        dispatch(saveBankDetails(payload));
    };

    const renderAlert = () => {
        if (alert.type === "custom")
            return (
              <SweetAlert
                custom={alert.type === "custom"}
                style={{ display: "block", marginTop: "-100px" }}
                title={alert.title || ""}
                onConfirm={() => {
                    setCheckBankAccountVerified(false);
                    dispatch(setAlert({show:false}));
                }}
                confirmBtnText={"Edit"}
                confirmBtnCssClass={classes.button + " " + classes.danger}
                show={!!alert.show}
                customIcon={<img src={HourglassLoader} style={{width: "40%"}} alt={"Hourglass"}/>}
              >
                  {alert.message}
              </SweetAlert>
            );

        return (
            <SweetAlert
                success={alert.type === "success"}
                warning={alert.type === "warning"}
                info={alert.type === "info"}
                style={{ display: "block", marginTop: "-100px" }}
                title={alert.title || ""}
                onConfirm={() => {
                    if (alert.type === 'success'){
                        dispatch(clearBankDetailsForm());
                    }
                    dispatch(fetchPractitionerBankAccounts());
                    dispatch(setAlert({ show: false }));
                }}
                onCancel={() => dispatch(setAlert({ show: false }))}
                confirmBtnCssClass={classes.button + " " + (alert.type === "success" ? classes.success : classes.warning)}
                show={!!alert.show}
            >
                  {alert.message}
            </SweetAlert>
        );
    };

    const renderBankDetails = () => {
        if (!selectedBankAccount)
            return null;

        return (
          <React.Fragment>
              <p>Bank: <span>{selectedBankAccount.bank}</span></p>
              <p>Account Name: <span>{selectedBankAccount.account_name}</span></p>
              <p>Account Number: <span>{selectedBankAccount.account_number}</span></p>
              <p>Branch Code: <span>{selectedBankAccount.branch_code}</span></p>
              <p>Status: <span>{titleCase(selectedBankAccount.status)}</span></p>
          </React.Fragment>
        );
    };

    const renderBankDetailsInputFields = () => {
        return (
          <React.Fragment>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >

                  <Autocomplete
                    options={banks}
                    getOptionLabel={(p) => p ? `${p}` : ""}
                    value={formFields["selectedBank"]}
                    onChange={handlebankSelected}
                    openOnFocus={true}
                    filterOptions={bankSelectionFilterOptions}
                    renderOption={(o) => <p>{o}</p>}
                    renderInput={(params) => <TextField
                      label="Please select your bank"
                      {...params}
                      inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // Avoid Auto Complete
                      }}
                    />}
                  />
              </FormControl>

              <CustomInput
                labelText="Account Name"
                id="account_name"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "accountName",
                    value: formFields["accountName"],
                    onChange: (e) => dispatch(setBankDetailsFormField("accountName", e.target.value))
                }}
              />
              <CustomInput
                labelText="Account Number"
                id="account_number"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "accountNumber",
                    value: formFields["accountNumber"],
                    onChange: (e) => dispatch(setBankDetailsFormField("accountNumber", e.target.value))
                }}
              />
              <CustomInput
                labelText="Branch Code"
                id="branchCode"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "branchCode",
                    value: formFields["branchCode"],
                    onChange: (e) => dispatch(setBankDetailsFormField("branchCode", e.target.value))
                }}
              />

              <Button color="rose" onClick={handleSubmit}
                      disabled={isInvalid}>Save Bank Details</Button>
          </React.Fragment>
        );
    };

    const renderForm = () => {

        return (
          <form>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >

                  <Autocomplete
                    options={filteredPractices()}
                    getOptionLabel={(p) => p ? `[${p.site.name} - ${p.name}] ${p.name}` : ""}
                    value={formFields["selectedPractice"]}
                    onChange={handlePracticeSelected}
                    openOnFocus={true}
                    groupBy={(p) => `${p.site.name} - ${p.name}`}
                    filterOptions={practitionerSelectionFilterOptions}
                    renderOption={(o) => <p>{o.name}</p>}
                    renderInput={(params) => <TextField
                      label="Select a Practice"
                      {...params}
                      inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // Avoid Auto Complete
                      }}
                    />}
                  />
              </FormControl>

              { (selectedBankAccount && ["invalid"].indexOf(selectedBankAccount.status) >= 0) ?
                renderBankDetailsInputFields() : renderBankDetails()
              }

          </form>
        );
    };

    return (
      <div>
          {renderAlert()}
          <GridContainer>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <BankIcon/>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Update your bank details</h4>
                  </CardHeader>
                  <CardBody>
                      {renderForm()}
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    );
}
