export const SET_BANK_DETAILS_FORM_FIELD = "SET_BANK_DETAILS_FORM_FIELD";
export const CLEAR_BANK_DETAILS_FORM = "CLEAR_BANK_DETAILS_FORM";
export const SET_BANKS = "SET_BANKS";
export const SAVE_BANKING_DETAILS = "SAVE_BANKING_DETAILS";
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const GET_PRACTICE_BANK_ACCOUNT = "GET_PRACTICE_BANK_ACCOUNT";
export const SET_PRACTICE_BANK_ACCOUNT = "SET_PRACTICE_BANK_ACCOUNT";
export const CLEAR_PRACTICE_INFORMATION_FORM = "CLEAR_PRACTICE_INFORMATION_FORM";
export const SET_PRACTICE_INFORMATION_FORM_FIELD = "SET_PRACTICE_INFORMATION_FORM_FIELD";
export const SAVE_PRACTICE_INFORMATION = "SAVE_PRACTICE_INFORMATION";
export const SET_PRACTICE_INFORMATION = "SET_PRACTICE_INFORMATION";
export const FETCH_PRACTICE_DETAILS = "FETCH_PRACTICE_DETAILS";

export const setBankDetailsFormField = (field, value) => ({type: SET_BANK_DETAILS_FORM_FIELD, payload: {field, value}});
export const clearBankDetailsForm = () => ({type: CLEAR_BANK_DETAILS_FORM});
export const saveBankDetails = (payload) => ({type: SAVE_BANKING_DETAILS, payload});
export const setSettingsLoading = (setting, value) => ({type: SETTINGS_LOADING, payload: { setting , value}});
export const checkBankAccountDetails = (practice_id) => ({type:GET_PRACTICE_BANK_ACCOUNT, payload: practice_id});
export const setPracticeBankAccountDetails = (payload) => ({type:SET_PRACTICE_BANK_ACCOUNT, payload});
export const clearPracticeInformationForm = () => ({type: CLEAR_PRACTICE_INFORMATION_FORM})
export const setPracticeInformationFormField = (field, value) => ({type: SET_PRACTICE_INFORMATION_FORM_FIELD, payload: {field, value}})
export const savePracticeInformation = (payload) => ({type: SAVE_PRACTICE_INFORMATION, payload});
export const setPracticeInformation = (payload) => ({type: SET_PRACTICE_INFORMATION, payload})
export const fetchPracticeDetails = (practice_id) => ({type: FETCH_PRACTICE_DETAILS, payload: practice_id})
