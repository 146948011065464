import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Notifications from "@material-ui/icons/Notifications";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Banner from "components/Banner/Banner";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import withAutoLogout from "hoc/AutoLogoutHOC";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { fetchPractice, setWorkingHours, fetchPractitionerIsCalendarUser, fetchWorkingHours} from "redux/actions/telehealth";
import { fetchFromLocalStorage } from "redux/actions/storage";
import {DATA_TYPE_JSON} from "redux/middleware/core/storage";
import adminHiddenRoutes from "../routes/admin-hidden-routes";
import { getLogoText } from "../constants/brandName";

var ps;

const useStyles = makeStyles(styles);
export const PractitionersContext = React.createContext(null);


const validateWorkingHours = (days) => {
    for (const [key, day] of Object.entries(days)) {
        if (day.length > 0) {
            return true
        }
    }
    return false
}

const WorkingHoursBanner = () => {
    return (
        <Banner color='info' show={window.location.pathname !== '/admin/working_hours'/*  && !hasSettings */}>
            <Notifications />
            {`Please use the "Working Hours" tab to set your available hours per day. Until edited, yours shows unavailable.`}
        </Banner>
    )
}

const Dashboard = (props) => {
    const is_calendar_user = useSelector(state => state.telehealth.is_calendar_user);

    const { ...rest } = props;
    // states and functions
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [miniActive, setMiniActive] = React.useState(false);

    // styles
    const classes = useStyles();
    const mainPanelClasses =
      classes.mainPanel +
      " " +
      cx({
          [classes.mainPanelSidebarMini]: miniActive,
          [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    // ref for main panel div
    const mainPanel = React.createRef();
    // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);

        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    });

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchPractice())
        dispatch(fetchPractitionerIsCalendarUser())
        dispatch(fetchWorkingHours())
    }, []);
    const practitioners = useSelector(state => state.telehealth.practitioners);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };
    const getActiveRoute = routes => {
        let activeRoute = "";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                  window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
            } else {
                return null;
            }
        });
    };
    const sidebarMinimize = () => {
        setMiniActive(!miniActive);
    };

    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    const filterRoutes = (routes) => {
        return routes.filter((r) => {

            if (r.filter === undefined)
                return true;

            if (r.path === "/working_hours")
                return is_calendar_user === true;

            return r.filter(props.roles);
        });
    };

    const getDefaultRoute = () => {
        const isPractitioner = props.roles.some((r) => r === 'practitioner');
        if (isPractitioner){
            return "/admin/pending_consults";
        }
        return "/admin/booking"
    };

    React.useEffect(() => {
        // TODO [SH]: Load the working hours. This could also be done on the WorkingHours view, but was needed here to validate if the user has working hours. 
        // Could be a better way to do this depending on how load from the server / local storage is done. Might use flags over loading the actual hours.
        
        // ** Note 'Validate working hours' helper function in this file. Should be used in WorkingHours view to set flag for banner.
        dispatch(fetchFromLocalStorage({ key: "working_hours", type: DATA_TYPE_JSON, onFetch: setWorkingHours }));
    }, [])

    const workingHours = useSelector(state => state?.telehealth?.workingHours?.general);
    const hasSettings = useSelector(state => state.settings);

    return (
      <div className={classes.wrapper}>
        {is_calendar_user && !validateWorkingHours(workingHours) && window.location.pathname !== '/admin/working_hours' ? WorkingHoursBanner(workingHours) : null}
          <PractitionersContext.Provider value={practitioners}>
              <Sidebar
                routes={filterRoutes(routes)}
                logoText={getLogoText}
                logo={require("assets/img/brand.png")}
                image={require("assets/img/sidebar.jpg")}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color="blue"
                bgColor="white"
                miniActive={miniActive}
                logout={props.logout}
                {...rest}
              />
              <div className={mainPanelClasses} ref={mainPanel}>
                  <AdminNavbar
                    sidebarMinimize={sidebarMinimize.bind(this)}
                    miniActive={miniActive}
                    brandText={getActiveRoute(routes)}
                    handleDrawerToggle={handleDrawerToggle}
                    logout={props.logout}
                    {...rest}
                  />
                  {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <Switch>
                                {getRoutes(filterRoutes(adminHiddenRoutes))}
                                {getRoutes(filterRoutes(routes))}
                                <Redirect from="/admin" to={getDefaultRoute()}/>
                            </Switch>
                        </div>
                    </div>
                  ) : (
                    <div className={classes.map}>
                        <Switch>
                            {getRoutes(filterRoutes(adminHiddenRoutes))}
                            {getRoutes(filterRoutes(routes))}
                            <Redirect from="/admin" to={getDefaultRoute()}/>
                        </Switch>
                    </div>
                  )}
                  {getRoute() ? <Footer fluid/> : null}
              </div>
          </PractitionersContext.Provider>
      </div>
    );
};

export default withAutoLogout(Dashboard);
