import {
  SET_PRACTITIONER_PRACTICES,
  SET_PRACTITIONERS,
  SET_WORKING_HOURS,
  SET_PRACTITIONER_IS_CALENDAR_USER,
  SET_BOOKINGS_FOR_OVERRIDE,
  SET_CALENDAR_LOADING,
  CLOSE_OVERRIDE_DATE_SELECTOR,
  SET_CALENDAR_OVERRIDE_LOADING,
  SET_CALENDAR_ALERT,
  SAVE_WORKING_HOURS_FAILED
} from "../actions/telehealth";

const defaultHours = {
  "sunday": [],
  "monday": [],
  "tuesday": [],
  "wednesday": [],
  "thursday": [],
  "friday": [],
  "saturday": []
}

const initState = {
  practitioners: [],
  practitionerPractices: [],
  calendarLoading: false,
  calendarOverrideLoading: false,
  closeOverrideDateSelector: false,
  saveWorkingHoursFailed: false,
  calendarAlert: { show: false },
  workingHours: {
    general: {},
    overrides: [],
    overridesToDelete: []
  },
};

export const telehealthReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PRACTITIONERS:
      return { ...state, practitioners: action.payload };
    case SET_CALENDAR_ALERT:
      return { ...state, calendarAlert: action.payload };
    case SET_BOOKINGS_FOR_OVERRIDE:
      return { ...state, bookings_for_override: action.payload };
    case SET_PRACTITIONER_PRACTICES:
      return {...state, practitionerPractices: action.payload}
    case SET_WORKING_HOURS:
        return {...state, workingHours: action.payload || {
          general: defaultHours,
          overrides: [],
          overridesToDelete: []
        }};
    case SET_PRACTITIONER_IS_CALENDAR_USER:
      return { ...state, is_calendar_user: action.payload };
    case SET_CALENDAR_LOADING:
      return { ...state, calendarLoading: action.payload };
    case SAVE_WORKING_HOURS_FAILED:
      return { ...state, saveWorkingHoursFailed: action.payload };
    case SET_CALENDAR_OVERRIDE_LOADING:
      return { ...state, calendarOverrideLoading: action.payload };
    case CLOSE_OVERRIDE_DATE_SELECTOR:
      return { ...state, closeOverrideDateSelector: action.payload };

    default:
      return state;
  }
};
