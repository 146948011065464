export const fileTooLargeAlert = (maxFileSize) => {
    return {
        show: true,
        type: "warning",
        message: `Your attachments may not exceed ${formatBytes(maxFileSize)}!`,
        title: "Files too large!"
    }
}

export const tooManyFilesAlert = (numberOfFiles) => {
    return {
        show: true,
        type: "warning",
        message: `You can only attach upto ${numberOfFiles} files!`,
        title: "Too many files!"
    }
}

export const duplicateFileAlert = (fileName) => {
    return {
        show: true,
        type: "warning",
        message: `You cannot have multiple attachments with the same file name: ${fileName}`,
        title: "Duplicate File!"
    }
}

export const isUploadComplete = (files, uploadStatus) => {
    let complete = true;

    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let fileIdentifier = `${file.md5}_${file.name}`;

        if (!(fileIdentifier in uploadStatus)){
            complete = false;
            break;
        }

        if (uploadStatus[fileIdentifier] !== 100){
            complete = false;
            break;
        }
    }

    return complete;
};

export const formatBytes = (a, b = 1) => {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1000));
    return parseFloat((a / Math.pow(1000, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

let SparkMD5 = require('spark-md5');

export async function getFileMd5(file) {
    return new Promise(function (resolve, reject) {
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        let chunkSize = 2097152;                             // Read in chunks of 2MB
        let chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();

        fileReader.onload = function (e) {
            spark.append(e.target.result);                   // Append array buffer
            currentChunk++;

            if (currentChunk < chunks) {
                loadNext();
            } else {
                resolve(spark.end());
            }
        };

        fileReader.onerror = function () {
            console.warn('oops, something went wrong.');
            reject('oops, something went wrong.');
        };

        function loadNext() {
            var start = currentChunk * chunkSize,
                end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }

        loadNext();
    });
};
