import {
    AUTH_SET_LOGIN_INFO,
    AUTH_LOGIN,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAILED,
    AUTH_SET_LOGGING_IN
} from "../actions/auth";

const initState = { login_info: {}, loginFailed: false, loggingIn: false };

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case AUTH_SET_LOGIN_INFO:
            return { ...state, login_info: action.payload };
        case AUTH_SET_LOGGING_IN:
            return { ...state, loggingIn: action.payload };
        case AUTH_LOGIN:
            return { ...state, loginFailed: false };
        case AUTH_LOGIN_SUCCESS:
            return { ...state, loginFailed: false };
        case AUTH_LOGIN_FAILED:
            return { ...state, loginFailed: true };
        default:
            return state;
    }
};