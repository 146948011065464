
// @material-ui/icons

import EditIcon from "@material-ui/icons/Edit";
import EditGroup from "../views/Booking/CreateBooking/EditGroup";
import AddBookingAttachments from "../views/Booking/CreateBooking/AddBookingAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const bookingsClerkFilter = (roles) => roles.some(r => r.startsWith('bookings:/'));

const adminHiddenRoutes = [
    {
        path: "/edit_booking/:bookingId",
        name: "Edit Booking",
        icon: EditIcon,
        component: EditGroup,
        layout: "/admin",
        filter: bookingsClerkFilter
    },
    {
        path: "/booking-attachments/:bookingId",
        name: "Booking Attachments",
        icon: AttachFileIcon,
        component: AddBookingAttachments,
        layout: "/admin",
        filter: bookingsClerkFilter
    }
];
export default adminHiddenRoutes;
