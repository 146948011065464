import {
    CLEAR_BANK_DETAILS_FORM, CLEAR_PRACTICE_INFORMATION_FORM,
    SET_BANK_DETAILS_FORM_FIELD,
    SET_BANKS,
    SET_PRACTICE_BANK_ACCOUNT,
    SET_PRACTICE_INFORMATION,
    SET_PRACTICE_INFORMATION_FORM_FIELD,
    SETTINGS_LOADING
} from "../actions/settings";
import { AUTH_LOGOUT_USER } from "../actions/auth";
import { BANK_NAMES } from "../../constants/bank_details";
import { SET_PRACTITIONER_BANK_ACCOUNTS, SET_PRACTITIONER_PRACTICES } from "../actions/telehealth";

const defaultBankDetailsForm = {
    selectedPractice: "",
    selectedBank: "",
    accountName: "",
    accountNumber: "",
    branchCode: ""
};

const defaultPracticeInformationForm = {
    selectedPractice: "",
    vat_number: ""
};

const initState = {
    banks: [...BANK_NAMES],
    bankDetailsFormFields: { ...defaultBankDetailsForm },
    practiceInformationFormFields: { ...defaultPracticeInformationForm },
    practitionerBankAccounts: null,
    networkRequests: {},
    selectedPracticeBankAccount: null,
    selectedPracticeInformation: null
};

let response;
export const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_PRACTICE_INFORMATION_FORM_FIELD:
            response = {
                ...state,
                practiceInformationFormFields: {
                    ...state.practiceInformationFormFields,
                    [action.payload.field]: action.payload.value
                }
            }
            if (action.payload.field === "selectedPractice") {
                response = {
                    ...state,
                    practiceInformationFormFields: {
                        ...defaultPracticeInformationForm,
                        [action.payload.field]: action.payload.value
                    }
                }
            }
            return response
        case SET_PRACTICE_INFORMATION:
            let {practice_id, ...fields} = action.payload;
            return {
                ...state,
                selectedPracticeInformation: fields,
                practiceInformationFormFields: {
                    ...defaultPracticeInformationForm,
                    selectedPractice: state.practiceInformationFormFields.selectedPractice,
                    ...fields
                }
            }
        case SET_BANK_DETAILS_FORM_FIELD:
            response =  {
                ...state,
                bankDetailsFormFields: { ...state.bankDetailsFormFields, [action.payload.field]: action.payload.value }
            };

            if (action.payload.field === "selectedPractice" && action.payload.value){
                let practice_id = action.payload.value.id;
                let currentAc = (state.practitionerBankAccounts || []).find((account) => {
                    if (!!practice_id)
                        return account.practice_id === practice_id;
                    return false;
                })
                if (currentAc){
                    response = {
                        ...response,
                        bankDetailsFormFields:{
                            ...response.bankDetailsFormFields,
                            selectedBank: currentAc.bank,
                            accountName: currentAc.account_name,
                            accountNumber: currentAc.account_number,
                            branchCode: currentAc.branch_code
                        }
                    }
                }
            }

            return response

        case SET_BANKS:
            return { ...state, practitioners: action.payload };
        case CLEAR_BANK_DETAILS_FORM:
            return { ...state, bankDetailsFormFields: { ...defaultBankDetailsForm }, selectedPracticeBankAccount: null };
        case CLEAR_PRACTICE_INFORMATION_FORM:
            return { ...state, practiceInformationFormFields: { ...defaultPracticeInformationForm }, selectedPracticeInformation: null };
        case SET_PRACTITIONER_BANK_ACCOUNTS:
            return {...state, practitionerBankAccounts: [...action.payload]}
        case AUTH_LOGOUT_USER:
            return { ...state, bankDetailsFormFields: {} };
        case SETTINGS_LOADING:
            return {...state, networkRequests: {...state.networkRequests, [action.payload.setting]: action.payload.value}}
        case SET_PRACTICE_BANK_ACCOUNT:
            return {...state, selectedPracticeBankAccount: {...action.payload}}
        case SET_PRACTITIONER_PRACTICES:
            return {...state, practitionerBankAccounts: [action.payload.map((practice)=>practice.bank_account_details)]}

        default:
            return state;
    }
};
