import React, {useState} from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import terms from "./terms";

const TermsAndConditions = () => {
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1.5);

    const onDocumentLoadSuccess = (result) => {
        setNumPages(result.numPages)
    }

    return (
        <div>
            <Document
                file={`data:application/pdf;base64,${terms}`}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                scale={scale}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )
                }
            </Document>
        </div>
    )
}

export default TermsAndConditions;