export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_BOOKINGS_LOADING = 'SET_BOOKINGS_LOADING';
export const SET_ALERT = 'SET_ALERT';
export const PERFORM_BOOKING_ACTION = 'PERFORM_BOOKING_ACTION';
export const MAKE_BOOKING = 'MAKE_BOOKING';
export const SET_BOOKING_FORM_FIELD = 'SET_BOOKING_FORM_FIELD';
export const CLEAR_BOOKINGS_FORM = 'CLEAR_BOOKINGS_FORM ';
export const SET_BOOKINGS_REQUEST_TIMESTAMP = 'SET_BOOKINGS_REQUEST_TIMESTAMP';
export const FETCH_BOOKING = "FETCH_BOOKING";
export const SET_BOOKING = "SET_BOOKING";
export const FETCH_SURVEY_QUESTIONS = "FETCH_SURVEY_QUESTIONS";
export const SET_SURVEY_QUESTIONS = "SET_SURVEY_QUESTIONS";
export const SAVE_BOOKING_ATTRIBUTES = "SAVE_BOOKING_ATTRIBUTES";

export const fetchBookings = () => ({type: FETCH_BOOKINGS});
export const performBookingAction = (bookingId, action) => ({type: PERFORM_BOOKING_ACTION, payload: {bookingId, action}});
export const setBookings = (bookings, timestamp=null) => ({type: SET_BOOKINGS, payload: bookings, timestamp});
export const setBookingsLoading = (value) => ({type: SET_BOOKINGS_LOADING, payload: value});
export const setAlert = (alert) => ({type: SET_ALERT, payload: alert});
export const makeBooking = (booking) => ({type: MAKE_BOOKING, payload: booking});
export const setBookingFormField = (field, value) => ({type: SET_BOOKING_FORM_FIELD, payload: {field, value}});
export const clearBookingsForm = () => ({type: CLEAR_BOOKINGS_FORM});
export const setBookingsRequestTimestamp = (timestamp) => ({type: SET_BOOKINGS_REQUEST_TIMESTAMP, payload: timestamp})
export const FETCH_CONSULTS = 'FETCH_CONSULTS';
export const fetchConsults = () => ({type: FETCH_CONSULTS});
export const fetchBooking = (bookingId) => ({type: FETCH_BOOKING, payload: bookingId})
export const setBooking = (booking, timestamp) => ({type: SET_BOOKING, payload: booking, timestamp})
export const fetchSurveyQuestions = (booking_id) => ({type: FETCH_SURVEY_QUESTIONS, payload: booking_id})
export const setSurveyQuestions = (questions) => ({type: SET_SURVEY_QUESTIONS, payload: questions})
export const saveBookingAttributes = (payload) => ({type: SAVE_BOOKING_ATTRIBUTES, payload})