import { apiRequest, API_GET, API_POST } from "../../actions/api";
import {
    FETCH_BOOKINGS,
    PERFORM_BOOKING_ACTION,
    MAKE_BOOKING,
    FETCH_CONSULTS,
    setBookings,
    fetchBookings,
    setBookingsLoading,
    setAlert,
    clearBookingsForm,
    setBookingsRequestTimestamp,
    FETCH_BOOKING,
    setBooking,
    FETCH_SURVEY_QUESTIONS,
    setSurveyQuestions,
    SAVE_BOOKING_ATTRIBUTES
} from "../../actions/bookings";
import { push } from "../core/router";


const bookings = ({ dispatch, getState }) => next => action => {

    next(action);

    if (!([FETCH_BOOKINGS, PERFORM_BOOKING_ACTION, MAKE_BOOKING, FETCH_CONSULTS, FETCH_BOOKING, FETCH_SURVEY_QUESTIONS, SAVE_BOOKING_ATTRIBUTES].includes(action.type)))
        return;

    const allocateRequestTimestamp = () => {
        let requestTimestamp = new Date().toISOString();
        next(setBookingsRequestTimestamp(requestTimestamp));
        return requestTimestamp;
    };

    let timestamp;

    switch (action.type) {
        case FETCH_BOOKINGS:
            next(setBookingsLoading(true));
            timestamp = allocateRequestTimestamp();
            next(
              apiRequest({
                  path: "bookings",
                  method: API_GET,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      return setBookings(result, timestamp);
                  },
                  failure: () => setBookingsLoading(false)
              })
            );
            break;

        case FETCH_BOOKING:
            next(setBookingsLoading(true));
            timestamp = allocateRequestTimestamp();
            next(
              apiRequest({
                  path: `group-booking/${action.payload}`,
                  method: API_GET,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      return setBooking(result['bookings'], timestamp);
                  },
                  failure: () => setBookingsLoading(false)
              })
            );
            break;

        case FETCH_CONSULTS:
            next(setBookingsLoading(true));
            timestamp = allocateRequestTimestamp();
            next(
              apiRequest({
                  path: "consults",
                  method: API_GET,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      return setBookings(result, timestamp);
                  },
                  failure: () => setBookingsLoading(false)
              })
            );
            break;
        case PERFORM_BOOKING_ACTION:
            next(
              apiRequest({
                  path: `booking/action/${action.payload.bookingId}`,
                  method: API_POST,
                  data: {
                      action: action.payload.action
                  },
                  success: (result) => {
                      if (action.payload.action === "billing-completed" || action.payload.action === "cancel-booking") {
                          dispatch(fetchBookings());
                      }

                      if (action.payload.action === "resend-booking-practitioner" || action.payload.action === "resend-booking-patient") {
                          const messageTo = action.payload.action === "resend-booking-practitioner" ? "practitioner" : "patient";
                          dispatch(setAlert({
                              show: true,
                              type: "success",
                              message: `Booking has been resent sent to the ${messageTo}`,
                              title: "Booking Resent!"
                          }));
                      }
                  },
                  failure: () => {
                      dispatch(fetchBookings());
                      dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error performing the requested action - please try again or contact support.",
                          title: "Failed to perform action!"
                      }));
                  }
              })
            );
            break;

        case MAKE_BOOKING:
            next(setBookingsLoading(true));
            next(
              apiRequest({
                  path: "booking",
                  method: API_POST,
                  data: action.payload,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      next(clearBookingsForm());
                      dispatch(setAlert({
                          show: true,
                          type: "success",
                          message: "The booking has been created",
                          title: "Booking created!",
                          confirmAction: action.payload.booking_type === 'group consult facilitator' ? push(`/admin/booking-attachments/${result.booking_id}`) : null
                      }));
                  },
                  failure: () => {
                      next(setBookingsLoading(false));
                      dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error creating the booking.",
                          title: "Booking failed to create!"
                      }));
                  }
              })
            );
            break;
        case FETCH_SURVEY_QUESTIONS:
            next(setBookingsLoading(true));
            next(
              apiRequest({
                  path: `survey-questions/${action.payload}`,
                  method: API_GET,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      return setSurveyQuestions(result['questions']);
                  },
                  failure: () => setBookingsLoading(false)
              })
            );
            break;
        case SAVE_BOOKING_ATTRIBUTES:
            next(setBookingsLoading(true));
            next(
              apiRequest({
                  path: `booking-attributes`,
                  method: API_POST,
                  data: action.payload,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      dispatch(setAlert({
                          show: true,
                          type: "success",
                          message: "The booking attributes have been saved",
                          title: "Booking Attributes Saved!",
                          confirmAction: push(`/admin/edit_booking/${action.payload.booking_id}`)
                      }));
                  },
                  failure: () => {
                      dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "An error has occurred - the booking attributes have not been saved. Please try again",
                          title: "Failed to save!"
                      }));
                      next(setBookingsLoading(false));
                  }
              })
            );
            break;
        default:
            break;
    }

};

export default bookings;
