
// @material-ui/icons

import Settings from "../views/Settings/Settings";
import BackIcon from '@material-ui/icons/ArrowBack';
import BankIcon from '@material-ui/icons/AccountBalance';
import BankDetails from "../views/BankDetails/BankDetails";
import InfoIcon from '@material-ui/icons/Info';
import PracticeSettingsForm from "../views/PracticeSettings/PracticeSettingsForm";

const bookingsClerkFilter = (roles) => roles.some(r => r.startsWith('bookings:/'));
const practitionerFilter = (roles) => roles.some(r => r === 'practitioner');

const settingsRoutes = [
    {
        path: '/back',
        name: 'Back',
        icon: BackIcon,
        component: Settings,
        layout: "/admin",
    },
    {
        path: '/bank-details',
        name: 'Bank Details',
        icon: BankIcon,
        component: BankDetails,
        layout: "/settings",
        filter: practitionerFilter
    },
    {
        path: '/practice-information',
        name: 'Practice Information',
        icon: InfoIcon,
        component: PracticeSettingsForm,
        layout: "/settings",
        filter: practitionerFilter
    },


];
export default settingsRoutes;
