import {apiRequest, API_POST} from '../../actions/api'
import {
    FETCH_PRACTICE_DETAILS,
    GET_PRACTICE_BANK_ACCOUNT,
    SAVE_BANKING_DETAILS, SAVE_PRACTICE_INFORMATION, setPracticeBankAccountDetails, setPracticeInformation,
    setSettingsLoading
} from "../../actions/settings";
import { setAlert } from "../../actions/bookings";
import { awaitingVerificationAlertMessage, savedPendingVerification } from "../../../constants/bank_details";
import { fetchPractitionerPractices } from "../../actions/telehealth";

const SETTINGS_ACTIONS = [
    SAVE_BANKING_DETAILS,
    GET_PRACTICE_BANK_ACCOUNT,
    SAVE_PRACTICE_INFORMATION,
    FETCH_PRACTICE_DETAILS
];

const settings = ({dispatch, getState}) => next => action => {

    next(action);

    if (!(SETTINGS_ACTIONS.includes(action.type)))
        return;

    switch (action.type) {
        case FETCH_PRACTICE_DETAILS:
            let practices = getState().telehealth.practitionerPractices || [];
            let currentPractice = practices.filter((practice) => practice.id === action.payload)
            if (currentPractice.length > 0)
                  dispatch(setPracticeInformation(currentPractice[0].attributes))
            break;
        case SAVE_BANKING_DETAILS:
            dispatch(setSettingsLoading("saveBankAccount", true));
            dispatch(
              apiRequest({
                  path: 'save-practice-bank-account',
                  method: API_POST,
                  data: action.payload,
                  success: () => {
                      dispatch(setAlert({
                          show: true,
                          type: "success",
                          message: savedPendingVerification,
                          title: "Banking Details Saved!"
                      }));
                      dispatch(setSettingsLoading("saveBankAccount", false));
                      dispatch(fetchPractitionerPractices());
                  },
            failure: (result) => {
                dispatch(setSettingsLoading("saveBankAccount", false));
                dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error saving the Bank Details.",
                          title: "Failed to save Bank Details!"
                      }));
                dispatch(setSettingsLoading("saveBankAccount", false));
            }
              })
            );
            break;

        case SAVE_PRACTICE_INFORMATION:
            dispatch(setSettingsLoading("savePracticeInformation", true));
            dispatch(
              apiRequest({
                  path: 'save-practice-information',
                  method: API_POST,
                  data: action.payload,
                  success: () => {
                      dispatch(setAlert({
                          show: true,
                          type: "success",
                          message: "The Information provided has been updated successfully ",
                          title: "Updated!"
                      }));
                      dispatch(setSettingsLoading("savePracticeInformation", false));
                      dispatch(fetchPractitionerPractices());
                  },
                  failure: (result) => {
                      dispatch(setSettingsLoading("savePracticeInformation", false));
                      dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error saving the Practice Information.",
                          title: "Failed to save Practice Information!"
                      }));
                      dispatch(setSettingsLoading("savePracticeInformation", false));
                  }
              })
            );
            break;
        case GET_PRACTICE_BANK_ACCOUNT:
            dispatch(
              apiRequest({
                  path: 'get-practice-bank-account-details',
                  method: API_POST,
                  data: { "practice_id": action.payload },
                  success: (result) => {
                        dispatch(setPracticeBankAccountDetails(result.bank_account))
                  }
              })
            );
            break;
        default:
            break;
    }

};

export default settings;
