import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";

const bannerStyle = {
  banner: {
    fontSize: "1.25em",
    columnGap: "10px",
    padding: "1em",
    fontWeight: "700",
    lineHeight: "1",
    color: whiteColor,
    textAlign: "center",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    // position: "fixed",
    zIndex: '1033',
    width: '100%',
    transform: 'translateY(-110%)',
    transition: '300ms',
    '&.show': {
      transform: 'none',
    }
  },
  primary: {
    backgroundColor: primaryColor[0]
  },
  warning: {
    backgroundColor: warningColor[0]
  },
  danger: {
    backgroundColor: dangerColor[0]
  },
  success: {
    backgroundColor: successColor[0]
  },
  info: {
    backgroundColor: infoColor[0]
  },
  rose: {
    backgroundColor: roseColor[0]
  },
  gray: {
    backgroundColor: grayColor[0]
  }
};

export default bannerStyle;
