import React from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import TermsAndConditions from "views/TermsAndConditions/TermsAndConditionsDialog";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { login, resetPassword } from "redux/actions/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import TermsAndConditionsDialog from "views/TermsAndConditions/TermsAndConditionsDialog";
import { getLogoText } from "../../constants/brandName";

const useStyles = makeStyles(styles);

export default function LoginPage() {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    const [alert, setAlert] = React.useState(null);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [termsOpen, setTermsOpen] = React.useState(false);

    const dispatch = useDispatch();
    const loggingIn = useSelector(state => state.auth.loggingIn);
    const loginFailed = useSelector(state => state.auth.loginFailed);

    React.useEffect(() => {
        const timer = setTimeout(function() {
            setCardAnimation("");
        }, 700);

        return () => clearTimeout(timer);
    }, []);

    React.useEffect(() => {
        if (loginFailed) {

            setAlert(
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={"Login Failed!"}
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
              >
                  Failed to log in.
              </SweetAlert>
            );
            ;
        }
    }, [loginFailed]);

    const handleLogin = () => {
        dispatch(login({ email, password }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // handleLogin();
    };

    const showForgotPassword = () => {
        setAlert(
          <SweetAlert
            input
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Reset Password"
            onConfirm={email => {
                setAlert(null);
                dispatch(resetPassword(email))
            }}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.info}
            cancelBtnCssClass={classes.button + " " + classes.grey}
          >
              Type in your email address below and you will receive password reset instructions.
          </SweetAlert>
        );
    };

    const classes = useStyles();

    return (
      <div className={classes.container}>
          {alert}
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                  <form onSubmit={onSubmit}>
                      <Card login className={classes[cardAnimaton]}>

                          <CardBody>

                              <div className={classes.logoContainer}>
                                  <img src={require("assets/img/brand.png")}/>
                              </div>

                              <h3 className={classes.textCenter}>Welcome</h3>
                              <h4 className={classes.textCenter}>Log into {getLogoText()}</h4>

                              <CustomInput
                                labelText="Email..."
                                id="email"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    value: email,
                                    onChange: (e) => setEmail(e.target.value),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          <Email className={classes.inputAdornmentIcon}/>
                                      </InputAdornment>
                                    )

                                }}
                              />
                              <CustomInput
                                labelText="Password"
                                id="password"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    value: password,
                                    onChange: (e) => setPassword(e.target.value),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          <Icon className={classes.inputAdornmentIcon}>
                                              lock_outline
                                          </Icon>
                                      </InputAdornment>
                                    ),
                                    type: "password",
                                    autoComplete: "off"
                                }}
                              />
                              <p
                                className={classes.forgotPassword}
                                onClick={showForgotPassword}>
                                  Forgot Password?
                              </p>
                          </CardBody>
                          <CardFooter className={classes.justifyContentCenter}>
                              <div>
                                <Button onClick={handleLogin}
                                        type="submit"
                                        disabled={email === "" || password === "" || loggingIn}
                                        color="github" size="lg" block>
                                    Continue
                                </Button>
                                <div>
                                    <FormHelperText>
                                        By logging to this website you agree to our
                                        <br/>
                                        <span className={classes.termsText} onClick={() => setTermsOpen(true)}>terms and conditions</span>
                                    </FormHelperText>
                                </div>
                              </div>
                          </CardFooter>
                      </Card>
                  </form>
              </GridItem>
            </GridContainer>
            <TermsAndConditionsDialog open={termsOpen} onClose={() => setTermsOpen(false)}/>
      </div>
    );
}
