import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';

import TermsAndConditions from './TermsAndConditions';

const TermsAndConditionsDialog = ({open, onClose}) => {

    return (
        <Dialog open={open} scroll="paper" fullWidth={true} maxWidth="md">
            <DialogContent>
                <TermsAndConditions />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} style={{ color:"#8e24aa"}}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TermsAndConditionsDialog;