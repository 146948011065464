import React from 'react';

import {useSelector, useDispatch} from "react-redux";
import {logoutUser, fetchUserFromStorage} from "redux/actions/auth";

const TOKEN_NAMESPACE = 'https://telehealth.netcaretech.co.za/user';

const withAutoLogout = (WrappedComponent) => {
    return (props) => {

        const dispatch = useDispatch();
        const {exp} = useSelector(state => state.auth.login_info.token_info || {exp: null});
        const {token_info} = useSelector(state => state.auth.login_info);

        let roles = [];
        if (token_info) {
            roles = token_info[TOKEN_NAMESPACE].roles;
        }

        React.useEffect(() => dispatch(fetchUserFromStorage()), []);


        const logout = () => dispatch(logoutUser());

        React.useEffect(() => {

            if (exp === null) return () => {};

            const currentTimeMillis = (new Date()).getTime();
            const logoutTimeMillis = (exp)*1000;

            if (currentTimeMillis > logoutTimeMillis) {
                logout()
            } else {
                const timer = setTimeout(() => {
                    logout()
                }, (logoutTimeMillis-currentTimeMillis));
                return () => clearTimeout(timer);
            }
        }, [exp]);

        if (exp === null) {
            return <div></div>;
        }

        return <WrappedComponent
          logout={logout}
          roles={roles}
          {...props} />
    }
};

export default withAutoLogout;