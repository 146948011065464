import {apiRequest, API_GET, API_POST} from '../../actions/api'
import {
    FETCH_PRACTICES,
    setPractitioners,
    setPractitionerIsCalendarUser,
    FETCH_PRACTITIONER_PRACTICES,
    setPractitionerPractices,
    FETCH_PRACTITIONER_BANK_ACCOUNTS,
    setPractitionerBankAccounts,
    SET_WORKING_HOURS,
    FETCH_PRACTITIONER_IS_CALENDAR_USER,
    SET_PRACTITIONER_IS_CALENDAR_USER,
    FETCH_WORKING_HOURS,
    setWorkingHours,
    SAVE_WORKING_HOURS,
    FETCH_BOOKINGS_FOR_OVERRIDE,
    setBookingsForOverride,
    setCalendarLoading,
    closeOverrideDateSelector,
    setCalendarOverrideLoading,
    setCalendarAlert,
    saveWorkingHoursFailed
} from "../../actions/telehealth";
import {setPracticeInformation, setSettingsLoading} from "../../actions/settings";
import {saveToLocalStorage} from 'redux/actions/storage';
import {DATA_TYPE_JSON} from '../core/storage';
import {API_VCSLOTBOOKING_URL} from '../../consts'

const DEFAULT_WORKING_HOURS = {
    general: {
        "sunday": [],
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": []
    },
    overrides: [],
    overridesToDelete: []
}

const TELEHEALTH_ACTIONS = [
    FETCH_PRACTICES,
    FETCH_PRACTITIONER_PRACTICES,
    FETCH_PRACTITIONER_BANK_ACCOUNTS,
    SET_WORKING_HOURS,
    FETCH_PRACTITIONER_IS_CALENDAR_USER,
    SET_PRACTITIONER_IS_CALENDAR_USER,
    FETCH_WORKING_HOURS,
    SAVE_WORKING_HOURS,
    FETCH_BOOKINGS_FOR_OVERRIDE
];
const getPractitionersFormPractices = (practices) => {
    let practitioners = [];

    practices.forEach((practice) => {

        practice.practitioners.forEach((practitioner) => {
            practitioners.push({
                ...practitioner,
                uid: `/${practice.organization.id}/${practice.site.id}/${practice.id}/${practitioner.id}`,
                practice
            })
        });
    });

    return practitioners;
};

const telehealth = ({dispatch, getState}) => next => action => {

    next(action);

    if (!(TELEHEALTH_ACTIONS.includes(action.type)))
        return;

    switch (action.type) {
        case SET_WORKING_HOURS:
            if (action.payload) {
                dispatch(saveToLocalStorage({key: 'working_hours', value: action.payload, type: DATA_TYPE_JSON}))
            }
            break;
        case FETCH_WORKING_HOURS:
            next(setCalendarLoading(true));
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/practitioners/calendar`,
                    method: API_GET,
                    success: (result) => {
                        next(setCalendarLoading(false));
                        if (result.hasOwnProperty('error_type'))
                            return setWorkingHours(DEFAULT_WORKING_HOURS);
                        return setWorkingHours(result);
                    },
                    // success: (result) => setWorkingHours(result),
                    failure: (data) => {
                        setCalendarLoading(false);
                        if (data.hasOwnProperty('error_type') && data.error_type === "practitioner_calendar_not_found") {
                            return setWorkingHours(DEFAULT_WORKING_HOURS);
                        }
                        console.log(data);
                    }
                })
            );
            break;
        case FETCH_BOOKINGS_FOR_OVERRIDE:
            next(setCalendarOverrideLoading(true));
            next(setCalendarLoading(true));
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/practitioners/calendar/bookings_for_block`,
                    data: action.payload,
                    method: API_POST,
                    success: (result) => {
                        next(setCalendarOverrideLoading(false));
                        next(setCalendarLoading(false));
                        if (result.hasOwnProperty('error_type'))
                            return setBookingsForOverride(null);

                        if (result.bookings && result.bookings.length === 0) {
                            next(closeOverrideDateSelector(true));
                        }
                        return setBookingsForOverride(result);
                    },
                    // success: (result) => setWorkingHours(result),
                    failure: (data) => {
                        next(setCalendarOverrideLoading(false));
                        next(setCalendarLoading(false));
                        console.log(data);
                    }
                })
            );
            next(setCalendarOverrideLoading(false));
            break;
        case SAVE_WORKING_HOURS:
            next(setCalendarLoading(true));
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/practitioners/calendar`,
                    data: action.payload,
                    method: API_POST,
                    success: (result) => {
                        next(setCalendarLoading(false));
                        if (result.hasOwnProperty('success') && result.success === true) {
                            return dispatch(setCalendarAlert({
                                show: true,
                                type: "success",
                                message: "Your calendar settings have been saved",
                                title: "Calendar Settings Saved!"
                            }));
                        }
                        console.log(result);
                        next(saveWorkingHoursFailed(true));
                        dispatch(setCalendarAlert({
                            show: true,
                            type: "warning",
                            message: "There was an error saving your calendar settings",
                            title: "Failed to save calendar settings!"
                        }));
                    },
                    // success: (result) => setWorkingHours(result),
                    failure: (data) => {
                        next(setCalendarLoading(false));
                        next(saveWorkingHoursFailed(true));
                        dispatch(setCalendarAlert({
                            show: true,
                            type: "warning",
                            message: "There was an error saving your calendar settings",
                            title: "Failed to save calendar settings!"
                        }));
                    }
                })
            );
            break;
        case FETCH_PRACTITIONER_IS_CALENDAR_USER:
            next(
                apiRequest({
                    full_url: `${API_VCSLOTBOOKING_URL}/practitioners/is_calendar_user`,
                    method: API_GET,
                    success: (result) => setPractitionerIsCalendarUser(result.is_calendar_user),
                    failure: (data) => console.log(data)
                })
            );
            break;
        case FETCH_PRACTICES:
            next(
                apiRequest({
                    path: 'practices',
                    method: API_GET,
                    success: (result) => setPractitioners(getPractitionersFormPractices(result.practices))
                })
            );
            break;
        case FETCH_PRACTITIONER_PRACTICES:
            next(setSettingsLoading("getPractitionerPractices", true));
            next(
                apiRequest({
                    path: 'get-practitioner-practices',
                    method: API_GET,
                    success: (result) => {
                        next(setPractitionerPractices(result.practices));
                        next(setSettingsLoading("getPractitionerPractices", false));
                    },
                    failure: (result) => {
                        next(setSettingsLoading("getPractitionerPractices", false));
                    }
                })
            );
            break;
        case FETCH_PRACTITIONER_BANK_ACCOUNTS:
            let practices = getState().telehealth.practitionerPractices || [];
            let bankAccounts = practices.map((practice) => practice.bank_account_details)
            next(setPractitionerBankAccounts(bankAccounts));

            break;
        default:
            break;
    }

};

export default telehealth;
