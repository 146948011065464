import React, { useEffect, useState } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import EventIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";

import {
    clearBookingsForm,
    fetchBooking,
    makeBooking,
    performBookingAction,
    setBookingFormField
} from "../../../redux/actions/bookings";
import { push } from "../../../redux/middleware/core/router";
import RenderAlert from "./../renderAlert";
import BookingsTable from "./../BookingsTable";
import AddParticipantDialog from "./AddParticipantDialog";
import Button from "../../../components/CustomButtons/Button";
import moment from "moment";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { bookingActions, getPaymentStatus } from "../bookingUtils";

const useStyles = makeStyles(styles);
export default function ExtendedForms(props) {
    const dispatch = useDispatch();
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const userName = useSelector(s => s.auth.login_info.user.name);
    const { bookingId } = props.match.params;
    const formFields = useSelector(state => state.bookings.bookingFormFields);
    const { facilitator, participants } = useSelector(state => state.bookings.groupBooking ? state.bookings.groupBooking : {});
    const [show_add_patient_dialog, setShowAddPatientDialog] = useState(false);
    const [data, setData] = useState([]);

    const doAction = (booking_id, action) => {
        dispatch(performBookingAction(booking_id, action));
    };

    useEffect(() => {
        if (!bookingId || bookingId === ":bookingId") {
            dispatch(push("/admin/booking"));
            return;
        }
        dispatch(clearBookingsForm());
        dispatch(fetchBooking(bookingId));
        dispatch(setBookingFormField("group_booking_id", bookingId));
    }, []);

    useEffect(() => {
        if (!participants)
            return;

        setData(
          participants.map((booking, key) => (
            {
                id: booking.id,
                practitioner_name: booking.practitioner_name,
                patient_name: booking.patient_name,
                patient_email: booking.patient_email,
                patient_contact_number: booking.patient_contact_number,
                date: moment(booking.datetime).format("YYYY-MM-DD"),
                time: moment(booking.datetime).format("HH:mm"),
                relativeTime: moment(booking.datetime).fromNow(),
                date_time: moment(booking.datetime).format("YYYY-MM-DD HH:mm"),
                payment_status: getPaymentStatus(booking),
                booking_type: booking.booking_type,
                patient_number: booking.patient_number || "",
                actions: (
                  <div className="actions-right">

                      <CustomDropdown
                        hoverColor="info"
                        buttonText="Actions"
                        onClick={(e) => doAction(e.props.id, e.props.actionid)}
                        buttonProps={{
                            style: { marginBottom: "0" },
                            color: "rose"
                        }}
                        dropdownHeader="Actions"
                        dropdownList={bookingActions(booking)}
                      />
                  </div>
                )
            }
          ))
        );
    }, [participants]);

    const classes = useStyles();

    const handleSubmit = () => {
        const payload = {
            group_booking_id: bookingId,
            patient_name: formFields["name"],
            patient_number: formFields["patientNumber"],
            patient_contact_number: formFields["contactNumber"],
            patient_email: formFields["email"],
            payment_method: formFields["paymentMethod"],
            auto_payment: formFields["autoPayment"],
            booking_created_by: userName,
            booking_type: "group consult participant",
            group_name: facilitator.patient_name
        };

        if (payload.auto_payment) {
            payload["billing_amount"] = formFields["billingAmount"];
            payload["tariff_code"] = formFields["tariffCode"];
        }

        dispatch(makeBooking(payload));
        setTimeout(() => {
            dispatch(fetchBooking(bookingId));
            setShowAddPatientDialog(false);
        }, 2000);

    };

    const renderBookingDetails = () => {
        if (!facilitator)
            return null;

        return (
          <React.Fragment>
              <p>Practitioner Name: <span>{facilitator.practitioner_name}</span></p>
              <p>Practice Name: <span>{facilitator.practice_name}</span></p>
              <p>Consult
                  Date: <span>{`${new Date(facilitator.datetime).toLocaleDateString()} ${new Date(facilitator.datetime).toLocaleTimeString()}`}</span>
              </p>
          </React.Fragment>
        );
    };

    const renderAddPatientButton = () => {
        if (data.length >= 19)
            return null;

        return (
          <div className={classes.addPatient} onClick={() => setShowAddPatientDialog(true)}>
              <AddIcon/> <p>&nbsp; Add a Patient</p>
          </div>
        );
    };

    return (
      <div>
          <RenderAlert/>
          <AddParticipantDialog
            open={show_add_patient_dialog}
            bookingId={bookingId}
            formFields={formFields}
            onClose={() => setShowAddPatientDialog(false)}
            onSubmit={() => handleSubmit()}
          />
          <GridContainer>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <EventIcon/>
                      </CardIcon>

                      <h4 className={classes.cardIconTitle}>
                          {facilitator ? `Group Booking - ${facilitator.patient_name}` : "Group Booking"}
                      </h4>
                  </CardHeader>
                  <CardBody>
                      {bookingsLoading ? <p>Loading...</p> :
                        <React.Fragment>
                            {renderBookingDetails()}
                            <br/>
                            <h4>Group Consult Patient Details</h4>
                            <BookingsTable
                              data={data}
                              columnsToShow={["Patient", "Contact Number", "Patient Number", "Actions"]}
                            />
                            <br/>
                            {renderAddPatientButton()}
                        </React.Fragment>
                      }
                      <br/>
                      <Button
                        color="rose"
                        onClick={() => props.history.push('/admin/booking')}>
                          Close
                      </Button>
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    );
}
