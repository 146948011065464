export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
export const AUTH_SET_LOGIN_INFO = "AUTH_SET_LOGIN_INFO";
export const AUTH_LOGOUT_USER = "AUTH_LOGOUT_USER";
export const AUTH_SET_LOGGING_IN = "AUTH_SET_LOGGING_IN";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";

export const FETCH_AUTHENTICATED_USER_FROM_STORAGE = "FETCH_AUTHENTICATED_USER_FROM_STORAGE";
export const AUTHENTICATED_USER_FETCHED_FROM_STORAGE = "AUTHENTICATED_USER_FETCHED_FROM_STORAGE";

export const login = ({ email, password }) => ({ type: AUTH_LOGIN, payload: { email, password } });
export const loginSuccess = (token) => ({ type: AUTH_LOGIN_SUCCESS, payload: token });
export const loginFailure = () => ({ type: AUTH_LOGIN_FAILED });
export const setLoginInfo = (info) => ({ type: AUTH_SET_LOGIN_INFO, payload: info });
export const logoutUser = (user) => ({ type: AUTH_LOGOUT_USER });
export const setLoggingIn = (value) => ({ type: AUTH_SET_LOGGING_IN, payload: value });
export const resetPassword = (email) => ({ type: AUTH_RESET_PASSWORD, payload: email });

export const fetchUserFromStorage = () => ({ type: FETCH_AUTHENTICATED_USER_FROM_STORAGE });
export const userFetchedFromStorage = (user) => ({ type: AUTHENTICATED_USER_FETCHED_FROM_STORAGE, payload: user });