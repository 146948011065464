import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "../../../hooks/usePrevious";
import { setAlert } from "../../../redux/actions/bookings";
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ErrorIcon from "@material-ui/icons/Error";
import { useStyles } from "./file_upload_styles";
import {
    fileTooLargeAlert,
    isUploadComplete,
    tooManyFilesAlert,
    formatBytes,
    getFileMd5,
    duplicateFileAlert
} from "./utils";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import { getFileUploadUrl } from "../../../redux/actions/fileUpload";
import Button from "../../../components/CustomButtons/Button";


const FileUploadScene = ({ onActiveFile, maxFiles, maxFileSize, minFileSize, filePrefix, uploadFileType, allowMultipleFiles }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [newFiles, setNewFiles] = useState(null);

    const prevFile = usePrevious({ files });
    const classes = useStyles();
    const uploadStatus = useSelector(state => state.fileUpload);

    const startFileUpload = (file) => {
        dispatch(getFileUploadUrl(`${file.md5}_${file.name}`, `${filePrefix}${file.name}`, file, filePrefix.slice(0, -1)));
    };

    useEffect(() => {
        if (onActiveFile) {
            onActiveFile(files);
        }

        if (prevFile)
            if (prevFile.files)
                prevFile.files.forEach(file => URL.revokeObjectURL(file.preview));

        files.forEach((file) => {
            if (!(`${file.md5}_${file.name}` in uploadStatus)) {
                startFileUpload(file);
            }
        });

    }, [files]);

    const appendNewFilesToFiles = () => {
        setFiles([...files, ...newFiles]);
        setNewFiles(null);
        isUploadComplete(files, uploadStatus);
    };


    useEffect(() => {
        if (!newFiles)
            return;

        let totalFiles = files.length + newFiles.length;
        if (totalFiles > maxFiles) {
            dispatch(setAlert(tooManyFilesAlert(maxFiles)));
            setNewFiles(null);
            return;
        }

        let totalFileSize = files.reduce((a, b) => a + b.size, 0) + newFiles.reduce((a, b) => a + b.size, 0);
        if (totalFileSize > maxFileSize) {
            dispatch(setAlert(fileTooLargeAlert(maxFileSize)));
            setNewFiles(null);
            return;
        }

        let fileNames = files.map(({ name }) => name).concat(newFiles.map(({ name }) => name));
        if (fileNames && fileNames.length > 1) {
            let duplicates = fileNames.filter((item, index) => fileNames.indexOf(item) !== index);
            if (duplicates.length > 0) {
                dispatch(setAlert(duplicateFileAlert(duplicates[0])));
                setNewFiles(null);
                return;
            }
        }
        appendNewFilesToFiles();

    }, [newFiles]);

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();

        if (rejectedFiles.length > 0) {
            let failureMessage = "An error occurred when loading your file. Please ensure you have selected the correct file";
            if (rejectedFiles[0].file.type.indexOf(uploadFileType) < 0) {
                failureMessage = "Incorrect file type!";
            }
            if (rejectedFiles[0].file.size > maxFileSize) {
                failureMessage = `You have selected a file that is too large! Please select a file less than ${formatBytes(maxFileSize)}`;
            }

            if (rejectedFiles[0].file.size < minFileSize) {
                failureMessage = "You have selected a file that is too small! Please select a file greater than " + minFileSize.toString() + "Bytes";
            }
            dispatch(setAlert({
                show: true,
                type: "warning",
                message: failureMessage,
                title: "There was an error with your file!"
            }));
            return;
        }

        Promise.all(acceptedFiles.map(async file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            md5: await getFileMd5(file)
        }))).then((res) => {
            setNewFiles(res);
        });


        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = (e) => console.log("file reading has failed", e);

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: uploadFileType,
        minSize: minFileSize,
        maxSize: maxFileSize,
        multiple: allowMultipleFiles,
        onDrop
    });

    const renderDropBoxMessage = () => {
        if (isDragActive)
            return (
              <React.Fragment>
                  <p>Drop the file here ...</p>
              </React.Fragment>
            );

        if (files.length === 5)
            return null;

        if (files.length > 0 && files.length < 5)
            return (
              <React.Fragment>
                  <Button
                    color="info">
                      Add more files
                  </Button>
                  <br/>
                  <p className={classes.dragFileTextMedium}>You can upload PDF, PNG and JPG files</p>
              </React.Fragment>
            );

        return (
          <React.Fragment>
              <p className={classes.dragFileTextLarge}>Drag files here</p>
              <p className={classes.dragFileTextMedium}>- or -</p>
              <Button
                color="info">
                  Select files from your device
              </Button>
              <br/>
              <p className={classes.dragFileTextMedium}>You can upload PDF, PNG and JPG files</p>
          </React.Fragment>
        );
    };

    const renderDropZone = () => {
        return (
          <div className={classes.dropZone}
               {...getRootProps()}
          >
              <input {...getInputProps()} />
              {renderDropBoxMessage()}

          </div>
        );
    };

    const renderVideoPreview = (file) => {
        return (
          <video width={"auto%"} style={{ height: "60px" }} controls>
              <source src={file.preview} type={file.type}/>
              Your browser does not support HTML5 video.
          </video>
        );
    };

    const renderImagePreview = (file) => {
        return (
          <img width={"auto%"} style={{ height: "60px" }} src={file.preview} alt={file.name}/>
        );
    };

    const renderRemove = (index, file) => {
        return (
          <p className={classes.fileNameDiv}>
              <CloseIcon className={classes.removeIcon} onClick={() => {
                  onRemoveFile(index, file);
              }}/>
          </p>
        );
    };

    const onRemoveFile = (indexOfFileToRemove, fileToRemove) => {
        let remainingFiles = files.filter((file, index) => index !== indexOfFileToRemove);
        setFiles(remainingFiles);
    };

    const renderUploadProgress = (file) => {
        if (!uploadStatus[`${file.md5}_${file.name}`])
            return <div className={classes.fileNameDiv}/>;

        if (uploadStatus[`${file.md5}_${file.name}`] === "waiting...")
            return <div className={classes.fileNameDiv}>Starting Upload...</div>;

        if (uploadStatus[`${file.md5}_${file.name}`] === "Failed!")
            return (
              <div
                onClick={() => {
                    startFileUpload(file);
                }}
                className={classes.fileNameDiv}
                style={{ cursor: "pointer" }}>Failed to Upload! Retry?
              </div>
            );

        if (uploadStatus[`${file.md5}_${file.name}`] >= 100)
            return <div className={classes.fileNameDiv}/>;

        return (
          <div className={`${classes.fileNameDiv} `}>
              <CustomLinearProgress
                variant="determinate"
                color="info"
                value={uploadStatus[`${file.md5}_${file.name}`]}
              />
          </div>
        );
    };

    const renderFilePreview = (file) => {
        if (file.type.indexOf("video/") === 0)
            return renderVideoPreview(file);
        else if (file.type.indexOf("image/") === 0)
            return renderImagePreview(file);
        else if (file.type.indexOf("application/") === 0)
            return <PictureAsPdfIcon className={`${classes.fileIcon} ${classes.pdfIcon}`}/>;
        else
            return <ErrorIcon className={classes.fileIcon}/>;
    };

    const renderFileList = () => {
        return files.map((file, index) => {
            return (
              <div key={index} className={classes.filePreviewListDiv}>
                  {renderFilePreview(file)}
                  <p className={classes.fileNameDiv}> {`${file.name} (${formatBytes(file.size)})`} </p>
                  {renderUploadProgress(file)}
                  {renderRemove(index, file)}
              </div>
            );
        });
    };

    const renderPopulatedFileList = () => {
        if (files.length < 1)
            return null;

        return (
          <div style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                  {renderFileList()}
              </div>
              <br/>
          </div>
        );
    };

    return (
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
          {renderPopulatedFileList()}
          {files.length < 5 && renderDropZone()}
      </div>
    );
};

FileUploadScene.defaultProps = {
    uploadFileType: "*",
    minFileSize: 0,
    allowMultipleFiles: false,
    maxFiles: 5
};


export default FileUploadScene;
