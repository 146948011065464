import { apiRequest, API_POST } from "../../actions/api";
import {
    GET_FILE_UPLOAD_URL, setFileUploadProgress
} from "../../actions/fileUpload";
import axios from "axios";


const fileUpload = ({ dispatch, getState }) => next => {
    return action => {

        next(action);
        if (!([GET_FILE_UPLOAD_URL].includes(action.type)))
            return;

        const getFormDataUsingResponse = (response, action) => {
            let formData = new FormData();
            for (let field in response[action.payload.fileName]["fields"]) {
                formData.append(field, response[action.payload.fileName]["fields"][field]);
            }
            formData.append("file", action.file);

            return formData
        }

        const uploadFile = (response, action) => {
            axios.post(
              response[action.payload.fileName]["url"],
              getFormDataUsingResponse(response, action),
              {
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: (progress) => dispatch(setFileUploadProgress(action.md5, progress.loaded / progress.total * 100))
                }
            ).then(null)
              .catch(error => {
                  dispatch(setFileUploadProgress(action.md5, "Failed!"))
                  console.error(error);
              });
        };

        switch (action.type) {
            case GET_FILE_UPLOAD_URL:
                dispatch(setFileUploadProgress(action.md5, "waiting..."));
                next(
                    apiRequest({
                      path: "attachment-upload-url",
                      method: API_POST,
                      data: { attachments: [action.payload.fileName], booking_id: action.payload.bookingID },
                      success: (result) => uploadFile(result, action),
                      failure: () => dispatch(setFileUploadProgress(action.md5, "Failed!"))
                    })
                );
                break;
            default:
                break;
        }

    };
};

export default fileUpload;
