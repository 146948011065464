import React, { useEffect } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { setBookingFormField } from "../../../redux/actions/bookings";
import Switch from "@material-ui/core/Switch";
import mask from "./../mask";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";

const formatCurrency = (input) => {
    return mask({ value: input, prefix: "R ", defaultValue: "R 0.00" });
};

const useStyles = makeStyles(styles);

const PatientFormFields = ({ formFields, duplicateParticipant }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [showBillingControls, setShowBillingControls] = React.useState(false);

    useEffect(() => {
        const practitioner = formFields["selectedPractitioner"];

        if (!practitioner || !(practitioner.practice))
            return;
        const onlineBillingAvailable = !!(practitioner.practice.online_billing_available);

        setShowBillingControls(onlineBillingAvailable);

        if (!onlineBillingAvailable) {
            // Cater for the case where a practice with billing available was selected previously
            dispatch(setBookingFormField("autoPayment", false));
        }

    }, [formFields["selectedPractitioner"]]);

    const handleChangePaymentMethod = event => {
        const paymentMethod = event.target.value;
        dispatch(setBookingFormField("paymentMethod", paymentMethod));
        if (paymentMethod !== "private") {
            dispatch(setBookingFormField("autoPayment", false));
            handleBillingAmountChanged({ target: { value: 0 } });
        }
    };

    const handleBillingAmountChanged = event => {
        const { value, maskedValue } = formatCurrency(event.target.value);
        dispatch(setBookingFormField("billingAmountMasked", maskedValue));
        dispatch(setBookingFormField("billingAmount", value));
    };

    const renderPatientForm = () => {
        return (
          <React.Fragment>
                {duplicateParticipant && <label style={{color: "red"}}> Another person with the same name exists in this consult!</label>}

              <CustomInput
                labelText="Patient's Name"
                id="patient_name"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "patients-name",
                    value: formFields["name"],
                    onChange: (e) => dispatch(setBookingFormField("name", e.target.value))
                }}
              />

              {!formFields["group_booking_id"]  ? null :
                <CustomInput
                  labelText="Patient Number (Optional)"
                  id="patient_number"
                  formControlProps={{
                      fullWidth: true
                  }}
                  inputProps={{
                      type: "text",
                      autoComplete: "patient-number",
                      value: formFields["patientNumber"],
                      onChange: (e) => dispatch(setBookingFormField("patientNumber", e.target.value))
                  }}
                />}

              <CustomInput
                labelText="Patient's Email Address"
                id="patient_email"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "email",
                    autoComplete: "patients-email",
                    value: formFields["email"],
                    onChange: (e) => dispatch(setBookingFormField("email", e.target.value))
                }}
              />
              <CustomInput
                labelText="Patient's Contact Number"
                id="patient_contact_number"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "tel",
                    autoComplete: "patients-contact-number",
                    value: formFields["contactNumber"],
                    onChange: (e) => dispatch(setBookingFormField("contactNumber", e.target.value))
                }}
              />
          </React.Fragment>
        );
    };

    const renderPaymentMethods = () => {
        return (
          <React.Fragment>
              <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <FormLabel
                        className={
                            classes.labelHorizontal +
                            " " +
                            classes.labelHorizontalRadioCheckbox +
                            " " +
                            classes.labelLeft
                        }
                      >
                          Payment Method
                      </FormLabel>
                  </GridItem>
                  <GridItem>
                      <FormControlLabel
                        control={
                            <Radio
                              checked={formFields["paymentMethod"] === "private"}
                              onChange={handleChangePaymentMethod}
                              value="private"
                              name="private"
                              aria-label="private"
                              icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                              }
                              checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                              }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Private"
                      />
                      <FormControlLabel
                        control={
                            <Radio
                              checked={formFields["paymentMethod"] === "medical_aid"}
                              onChange={handleChangePaymentMethod}
                              value="medical_aid"
                              name="medical_aid"
                              aria-label="medical_aid"
                              icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                              }
                              checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                              }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Medical Aid"
                      />
                      <FormControlLabel
                        control={
                            <Radio
                              checked={formFields["paymentMethod"] === "voucher"}
                              onChange={handleChangePaymentMethod}
                              value="voucher"
                              name="voucher"
                              aria-label="voucher"
                              icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                              }
                              checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                              }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label="Voucher"
                      />
                  </GridItem>
              </GridContainer>
          </React.Fragment>
        );
    };

    const renderBillingInfo = () => {

        if (formFields["paymentMethod"] !== "private" || !formFields["autoPayment"])
            return null;

        return (
          <>
              <CustomInput
                labelText="Billing Amount"
                id="billing_amount"
                helperText="R 5.00 is the minimum billing amount accepted"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "billing-amount",
                    value: formFields["billingAmountMasked"],
                    onChange: handleBillingAmountChanged
                }}
              />
              <CustomInput
                labelText="Tariff Code"
                id="tariff_code"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "tariff-code",
                    value: formFields["tariffCode"],
                    onChange: (e) => dispatch(setBookingFormField("tariffCode", e.target.value))
                }}
              />
          </>
        );
    };

    const renderBillingControls = () => {

        if (!showBillingControls) return null;

        return (
          <>
              <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <FormLabel
                        className={
                            classes.labelHorizontal +
                            " " +
                            classes.labelHorizontalRadioCheckbox +
                            " " +
                            classes.labelLeft
                        }
                      >
                          Create online payment link
                      </FormLabel>
                  </GridItem>
                  <GridItem>
                      <Switch
                        disabled={formFields["paymentMethod"] !== "private"}
                        checked={formFields["autoPayment"]}
                        onChange={e => {
                            dispatch(setBookingFormField("autoPayment", e.target.checked));
                            if (!e.target.checked)
                                handleBillingAmountChanged({ target: { value: 0 } });
                        }}
                        value="autoPayment"
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                      />
                  </GridItem>
              </GridContainer>
              {renderBillingInfo()}
          </>
        );
    };

    return (
      <React.Fragment>
          {renderPatientForm()}
          {formFields["group_booking_id"] === "" && renderPaymentMethods()}
          {renderBillingControls()}
      </React.Fragment>
    );
};

export default PatientFormFields;
