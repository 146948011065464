import React, { useEffect, useState } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AttachFileIcon from "@material-ui/icons/AttachFile";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import {
    fetchBooking,
    fetchSurveyQuestions,
    saveBookingAttributes,
    setSurveyQuestions
} from "../../../redux/actions/bookings";
import { push } from "../../../redux/middleware/core/router";
import RenderAlert from "./../renderAlert";
import Button from "../../../components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddFileDialog from "../file_upload_component/FileUploadDialog";
import { formatBytes } from "../file_upload_component/utils";
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "../../../components/CustomInput/CustomInput";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";
import { text2HTML } from "../bookingUtils";

const useStyles = makeStyles({
    ...customSelectStyle,
    addFile: {
        display: "flex",
        flexDirection: "row",
        cursor: "pointer"
    },
    attachmentPreviewListDiv: {
        display: "flex",
        marginBottom: "5px",
        width: "100%"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
});

export default function AddBookingAttachments(props) {
    const dispatch = useDispatch();

    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const { bookingId } = props.match.params;
    const { facilitator } = useSelector(state => state.bookings.groupBooking ? state.bookings.groupBooking : {});
    const [show_add_file_dialog, setShowAddFileDialog] = useState(false);
    const [attachmentFiles, setAttachmentFiles] = useState(null);
    const [notes, setNotes] = useState("");
    const [surveyQuestion, setSurveyQuestion] = useState(null);
    const surveyQuestions = useSelector(state => state.bookings.surveyQuestions);

    useEffect(() => {
        if (!bookingId || bookingId === ":bookingId") {
            dispatch(push("/admin/booking"));
            return;
        }
        dispatch(fetchBooking(bookingId));
        dispatch(fetchSurveyQuestions(bookingId));

        return (() => {
            dispatch(setSurveyQuestions([]));
        });
    }, []);

    useEffect(()=> {
        if (surveyQuestion) return;
        if (surveyQuestions.length === 1)
            setSurveyQuestion(surveyQuestions[0].question_id);

    }, [surveyQuestions, surveyQuestion])

    const classes = useStyles();

    const handleSubmit = () => {
        const payload = {
            booking_id: bookingId,
            attachments: attachmentFiles ? attachmentFiles.map(({ name }) => name) : [],
            survey_question_id: surveyQuestion,
            pre_read_notes: text2HTML(notes)
        };
        dispatch(saveBookingAttributes(payload));
    };

    const renderBookingDetails = () => {
        if (!facilitator)
            return null;

        return (
          <React.Fragment>
              <p>Practitioner Name: <span>{facilitator.practitioner_name}</span></p>
              <p>Practice Name: <span>{facilitator.practice_name}</span></p>
              <p>Consult
                  Date: <span>{`${new Date(facilitator.datetime).toLocaleDateString()} ${new Date(facilitator.datetime).toLocaleTimeString()}`}</span>
              </p>
          </React.Fragment>
        );
    };

    const renderRemoveIcon = (index, file) => {
        return (
          <p className={classes.fileNameDiv}>
              <CloseIcon className={classes.removeIcon} onClick={() => {
                  onRemoveFile(index, file);
              }}/>
          </p>
        );
    };

    const onRemoveFile = (indexOfFileToRemove, fileToRemove) => {
        let remainingFiles = attachmentFiles.filter((file, index) => index !== indexOfFileToRemove);
        setAttachmentFiles(remainingFiles);
    };

    const renderFileList = () => {
        return attachmentFiles.map((file, index) => {
            return (
              <div key={index} className={classes.attachmentPreviewListDiv}>
                  {renderRemoveIcon(index, file)}
                  <p className={classes.fileNameDiv}>&nbsp; {`${file.name}`} </p>
                  <p className={classes.fileNameDiv}>&nbsp;&nbsp; {`${formatBytes(file.size)}`} </p>
              </div>
            );
        });
    };

    const renderAttachments = () => {
        if (!attachmentFiles || attachmentFiles.length < 1)
            return null;

        return (
          <div style={{ width: "100%" }}>
              <h4>Attachment/s</h4>
              <div style={{ width: "100%" }}>
                  {renderFileList()}
              </div>
              <br/>
          </div>
        );
    };

    const renderSurveyQuestion = () => {
        return (
          <FormControl
            fullWidth
            className={classes.selectFormControl}>

              <Autocomplete
                options={surveyQuestions}
                value={surveyQuestion ? surveyQuestions.find((question) => question.question_id === surveyQuestion) : null}
                getOptionLabel={(p) => p ? p.question : ""}
                onChange={(e, value) => setSurveyQuestion(value.question_id)}
                openOnFocus={true}
                renderOption={(o) => <p>{o.question}</p>}
                renderInput={(params) => <TextField
                  label="Select a post consult survey rating question"
                  {...params}
                  inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // Avoid Auto Complete
                  }}
                />}
              />
          </FormControl>
        );
    };

    const renderNotesInput = () => {
        return (
          <FormControl
            fullWidth
            className={classes.selectFormControl}>
              <CustomInput
                labelText="Pre Read Notes:"
                id="notes"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "notes",
                    value: notes,
                    multiline: true,
                    rows: 4,
                    onChange: (e) => setNotes(e.target.value)
                }}
              />
          </FormControl>
        );
    };

    const renderContent = () => {
        if (bookingsLoading)
            return <p>Loading...</p>;

        return (
          <React.Fragment>
              {renderBookingDetails()}
              <br/>
              <h4>Post Consult Survey:</h4>
              {renderSurveyQuestion()}
              <h4>Booking Pre-Read Content:</h4>
              {renderNotesInput()}
              <br/>
              {renderAttachments()}
              <div className={classes.addFile} onClick={() => setShowAddFileDialog(true)}>
                  <AddIcon/> <p>&nbsp; Add Attachment/s</p>
              </div>
              <br/>
              <Button
                color="rose"
                disabled={!surveyQuestion}
                onClick={handleSubmit}>
                  Save Booking Attributes
              </Button>
          </React.Fragment>
        );
    };

    return (
      <div>
          <RenderAlert/>
          <AddFileDialog
            open={show_add_file_dialog}
            onClose={() => setShowAddFileDialog(false)}
            filePrefix={`${bookingId}/`}
            onSubmit={(files) => {
                setShowAddFileDialog(false);
                setAttachmentFiles(files);
            }}
          />
          <GridContainer>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <AttachFileIcon/>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                          Booking Attributes
                      </h4>
                  </CardHeader>
                  <CardBody>
                      {renderContent()}
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    );
}
