import React from "react";
import {useDispatch, useSelector} from "react-redux";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Dialog from "@material-ui/core/Dialog";
import Slider from "@material-ui/core/Slider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Warning from "@material-ui/icons/WarningRounded";
import Trash from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Lock from "@material-ui/icons/Lock";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

// import moment from "moment";
import moment from 'moment-timezone'

import {
    setWorkingHours,
    saveWorkingHours,
    fetchBookingsForOverride,
    closeOverrideDateSelector, saveWorkingHoursFailed, fetchWorkingHours
} from "redux/actions/telehealth";

import Loader from "react-loader-spinner";
import {useHistory} from "react-router-dom";

import {ThemeProvider} from "@material-ui/styles";
import {scheduleHours, workingHoursStyles, materialTheme} from "./utils"
import SweetAlert from "react-bootstrap-sweetalert";
import {setCalendarAlert} from "../../redux/actions/telehealth";


const useStyles = makeStyles(workingHoursStyles);

const PopupDialog = (props) => {
    const {classes, open, onClose, children, title, description, icon} = props

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={`${classes.popupContainer} info`}>
                <div className={classes.popupIcon}>{icon}</div>
                <div className={`${classes.popupTitle} clash`}>{title}</div>
                <div className={classes.popupMessage}>{description}</div>
                <div className={classes.popupButtonRow}>{children}</div>
            </div>
        </Dialog>
    )
}

const validateOverride = (override, bookings) => {
    const combinedStart = moment(`${override?.date?.format('Do MMMM YYYY')} ${override?.start}`, "Do MMMM YYYY HH:mm")
    const combinedEnd = moment(`${override?.date?.format('Do MMMM YYYY')} ${override?.end}`, "Do MMMM YYYY HH:mm")

    for (const booking of bookings) {
        const formatedBooking = moment(booking, "YYYY/MM/DD HH:mm")
        if (formatedBooking.isBetween(combinedStart, combinedEnd)) {
            return false
        }
    }

    return true
}

const minutesToTime = (value) => {
    const hours = Math.floor(value / 60)
    const mins = (value % 60)
    return `${hours < 10 ? '0' : ''}${hours}:${mins || '00'}`
}

const CustomIconButton = (props) => {
    const {onClick, icon} = props
    return (<IconButton onClick={onClick}>{icon}</IconButton>)
}

const TimeSelectOptions = (props) => {
    const {label, onChange, value, classes, min, max} = props
    const minMax = {min: min || '00:00', max: max || '23:30'}
    const validHours = Object.entries(scheduleHours).slice(scheduleHours[minMax.min].index + (min ? 1 : 0), scheduleHours[minMax.max].index + (max ? 0 : 1))

    return (
        <Select
            value={value}
            onChange={onChange}
            variant={'outlined'}
            MenuProps={{classes: {paper: classes.menuPaper}}}>
            <MenuItem disabled value="" key={'name'}>
                <em>{label}</em>
            </MenuItem>
            {validHours.map(([index, item]) => {
                return <MenuItem key={index} value={item.time}>{item.time}</MenuItem>
            })}
        </Select>
    )
}

const DailyHours = (props) => {
    const {day, days, setDays, classes} = props;

    if (!(day && days && setDays && classes)) {
        return null;
    }

    return (
        <Grid item className={classes.gridItem}>
            <Checkbox checked={day.timeSlots.length > 0} onChange={event => {
                setDays({
                    ...days,
                    [day.index]: event.target.checked ? [{"start": "09:00", "end": "17:00", "unsaved": true}] : []
                });
            }}/>

            <div className={classes.padded}>{day.index.slice(0, 3)}</div>

            {day.timeSlots.length > 0 ? <div className={classes.timeColumn}>
                {day.timeSlots.map((timeSlot, index) => <div key={`timeslot_${index}`}
                                                             className={classes.timeSelection}>
                    {timeSlot.unsaved && <>
                        <TimeSelectOptions
                            classes={classes}
                            value={timeSlot.start}
                            max={timeSlot.end}
                            onChange={
                                (event) => {
                                    day.timeSlots.splice(index, 1, {
                                        start: event.target.value,
                                        end: timeSlot.end,
                                        unsaved: true
                                    })
                                    setDays({...days, [day.index]: day.timeSlots});
                                }
                            }
                            label="Start Time"
                        />
                        <TimeSelectOptions
                            classes={classes}
                            value={timeSlot.end}
                            min={timeSlot.start}
                            onChange={
                                (event) => {
                                    day.timeSlots.splice(index, 1, {
                                        start: timeSlot.start,
                                        end: event.target.value,
                                        unsaved: true
                                    })
                                    setDays({...days, [day.index]: day.timeSlots});
                                }
                            }
                            label="End Time"
                        />
                    </>}

                    {!timeSlot.unsaved && <>
                        <div className={classes.timeSlot}>{timeSlot.start} <Lock/></div>
                        <div className={classes.timeSlot}>{timeSlot.end} <Lock/></div>
                    </>}

                    <CustomIconButton icon={<Trash/>} onClick={() => {
                        day.timeSlots.splice(index, 1)
                        setDays({...days, [day.index]: day.timeSlots});
                    }}/>
                </div>)}
            </div> : <div className={`${classes.padded} faded`}>{'UNAVAILABLE'}</div>}

            <div/>

            <CustomIconButton icon={<Add/>} onClick={() => {
                setDays({
                    ...days,
                    [day.index]: [...day.timeSlots, {"start": "10:00", "end": "15:00", "unsaved": true}]
                });
            }}/>

        </Grid>
    )
}

const DateSelector = ({
                          classes,
                          overrideDates,
                          dateSelectorOpen,
                          setOverrideDates,
                          setDateSelectorOpen,
                          bookings,
                          setClashOpen
                      }) => {
    const [pickerTimes, setPickerTimes] = React.useState([540, 1020]);
    const [selectedDate, setSelectedDate] = React.useState(moment());
    const calendarOverrideLoading = useSelector(state => state.telehealth.calendarOverrideLoading);
    const bookings_for_override = useSelector(state => state.telehealth.bookings_for_override);
    const closeOverrideDateSelectorVar = useSelector(state => state.telehealth.closeOverrideDateSelector);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (calendarOverrideLoading) {
            setDateSelectorOpen(true)
        }
    }, [calendarOverrideLoading]);

    React.useEffect(() => {
        if (bookings_for_override && bookings_for_override.bookings.length > 0) {
            setClashOpen(true)
        }
    }, [bookings_for_override]);

    React.useEffect(() => {
        if (closeOverrideDateSelectorVar) {
            const override = {
                date: selectedDate,
                start: minutesToTime(pickerTimes[0]),
                end: minutesToTime(pickerTimes[1])
            }
            if (validateOverride(override, bookings)) {
                override.date = override.date.tz("Africa/Johannesburg").format()
                setOverrideDates([...overrideDates, override]);
            }
            setDateSelectorOpen(false);
            dispatch(closeOverrideDateSelector(false));
        }
    }, [closeOverrideDateSelectorVar]);


    return (
        <ThemeProvider theme={materialTheme}>
            <Dialog open={dateSelectorOpen} onClose={() => {
                setDateSelectorOpen(false)
            }}>
                <div className={classes.popupContainer}>
                    <div className={classes.popupTitle}>Select the date and time you are unavailable</div>

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            open={dateSelectorOpen}
                            readOnly={true}
                            disablePast
                            disableToolbar
                            variant="static"
                            format="DD/MM/YYYY"
                            maxDate={moment().add(13, 'days')}
                            id="date-picker-inline"
                            value={selectedDate}
                            // onChange={setSelectedDate}
                            onChange={setSelectedDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <div className={classes.sliderAnnotations}>
                        <div>
                            <div className={classes.sliderAnnotationLabel}>BEGIN</div>
                            <div className={classes.sliderAnnotationValue}>{minutesToTime(pickerTimes[0])}</div>
                        </div>
                        <div className={'end'}>
                            <div className={classes.sliderAnnotationLabel}>END</div>
                            <div className={classes.sliderAnnotationValue}>{minutesToTime(pickerTimes[1])}</div>
                        </div>
                    </div>

                    <Slider
                        className={classes.slider}
                        value={pickerTimes}
                        min={420}
                        max={1200}
                        step={30}
                        onChange={(event, value) => setPickerTimes(value)}
                        valueLabelFormat={value => <div>{minutesToTime(value)}</div>}
                    />

                    <div className={classes.popupButtonRow}>
                        <Button className={`${classes.popupButton} outlined`}
                                onClick={() => setDateSelectorOpen(false)}>Cancel</Button>
                        <Button className={classes.popupButton} onClick={() => {
                            const override = {
                                date: selectedDate.tz("Africa/Johannesburg").format(),
                                start: minutesToTime(pickerTimes[0]),
                                end: minutesToTime(pickerTimes[1])
                            }

                            if (override) {
                                dispatch(fetchBookingsForOverride(override))
                            }
                            // if (validateOverride(override, bookings)) {
                            //     setOverrideDates([...overrideDates, override]);
                            // } else {
                            //     setClashOpen(true)
                            // }
                            setDateSelectorOpen(false)
                        }} disabled={calendarOverrideLoading}>Apply</Button>
                    </div>
                </div>
            </Dialog>
        </ThemeProvider>
    )
}

export default function ReactTables() {

    const dispatch = useDispatch();

    const workingHours = useSelector(state => state.telehealth.workingHours)
    const calendarLoading = useSelector(state => state.telehealth.calendarLoading);
    const saveWorkingHoursFailed = useSelector(state => state.telehealth.saveWorkingHoursFailed);

    const [daysOfTheWeek, setDaysOfTheWeek] = React.useState({})
    const [overrideDates, setOverrideDates] = React.useState([]);
    const [unsavedData, setUnsavedData] = React.useState(false);
    const [saveTriggerCount, setSaveTriggerCount] = React.useState(0)

    React.useEffect(() => {
        dispatch(fetchWorkingHours());
        setDaysOfTheWeek({...JSON.parse(JSON.stringify(workingHours.general))})
        setOverrideDates([...JSON.parse(JSON.stringify(workingHours.overrides))])
    }, []);

    React.useEffect(() => {
        if (saveWorkingHoursFailed){
            setUnsavedData(true)
        }
    }, [saveWorkingHoursFailed]);

    React.useEffect(() => {
        if (saveTriggerCount > 1) {
            setUnsavedData(true)
        }
        setSaveTriggerCount(saveTriggerCount + 1)
    }, [overrideDates, daysOfTheWeek]);

    const bookings_for_override = useSelector(state => state.telehealth.bookings_for_override);
    const [dateSelectorOpen, setDateSelectorOpen] = React.useState(false);
    const [clashOpen, setClashOpen] = React.useState(false);
    const [unsavedOpen, setUnsavedOpen] = React.useState(false);
    const [deleteOverrides, setDeleteOverrides] = React.useState([]);
    const [navLink, setNavLink] = React.useState();
    const calendarAlert = useSelector(state => state.telehealth.calendarAlert);

    const navigate = useHistory().push
    const unblock = useHistory().block((props) => {
        if (unsavedData) {
            setNavLink(props.pathname)
            setUnsavedOpen(true)
            return false
        }
    })

    const bookings = [
        "2022/03/18 15:30",
        "2022/03/19 15:45",
        "2022/03/20 16:00",
    ];

    const renderCalendarAlert = () => {

        if (!calendarAlert.show) return null;

        return (
          <SweetAlert
            success={calendarAlert.type === "success"}
            warning={calendarAlert.type === "warning"}
            style={{ display: "block", marginTop: "-100px" }}
            title={calendarAlert.title}
            onConfirm={() => dispatch(setCalendarAlert({ show: false }))}
            onCancel={() => dispatch(setCalendarAlert({ show: false }))}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
              {calendarAlert.message}
          </SweetAlert>
        );
    };

    const classes = useStyles();
    return (
        <>
            {
              renderCalendarAlert()
            }
            <DateSelector
                classes={classes}
                bookings={bookings}
                overrideDates={overrideDates}
                dateSelectorOpen={dateSelectorOpen}
                setDateSelectorOpen={setDateSelectorOpen}
                setClashOpen={setClashOpen}
                setOverrideDates={setOverrideDates}
            />

            {/* Override clash dialog */}
            <PopupDialog
                classes={classes}
                open={clashOpen}
                onClose={() => setClashOpen(false)}
                title={'Override clash'}
                description={'You have scheduled an override that clashes with an existing appointment'}
                icon={<Warning/>}
            >
                <Grid container
                      direction="column"
                      justifyContent="center"
                      alignItems="center">
                    <Grid item>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Appointments that are clashing are:
                        </Typography>
                    </Grid>
                    <Grid item>
                        {bookings_for_override?.bookings ? bookings_for_override?.bookings.map(function(d, idx){
                             return (<><li key={idx}>{moment(d).format("LLLL")}</li></>)
                           }) : null}
                    </Grid>
                    <Grid item>
                        <br/>
                        <Button color='rose' className={`${classes.popupButton} outlined`} onClick={() => {
                            setClashOpen(false);
                            setDateSelectorOpen(true);
                        }}>Change override</Button>
                    </Grid>
                </Grid>


            </PopupDialog>

            {/* Unsave data dialog */}
            <PopupDialog
                classes={classes}
                open={unsavedOpen}
                onClose={() => setUnsavedOpen(false)}
                title={'You have unsaved changes'}
                description={'Are you sure you want to close this window? You will lose your unsaved changes'}
                icon={<Warning/>}
            >
                <Button color='rose' className={`${classes.popupButton} outlined`}
                        onClick={() => setUnsavedOpen(false)}>Go back</Button>
                <Button color='rose' className={classes.popupButton} onClick={() => {
                    setUnsavedOpen(false);
                    unblock();
                    navigate(navLink)
                }}>Close without saving</Button>
            </PopupDialog>

            <GridContainer style={{minWidth: "1200px"}}>
                {/*<GridContainer>*/}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment/>
                            </CardIcon>
                            <div style={{display: "flex", alignItems: "center", margin: 15}}>
                                <h4 className={classes.cardIconTitle} style={{margin: 5}}>Working Hours</h4>
                                {
                                    calendarLoading && <Loader
                                        type="ThreeDots"
                                        color="#00acc1"
                                        height={100}
                                        width={100}
                                        timeout={15000} //15 secs
                                        style={{paddingLeft: "15"}}
                                    />
                                }

                            </div>
                        </CardHeader>
                        <CardBody>
                            <Grid container className={classes.doubleColumn}>
                                <Grid container className={classes.panel}>
                                    <div className={classes.panelTitle}>Set your weekly hours</div>
                                    <div className={classes.panelDescription}>To edit, select the box to the left of the
                                        day and then edit the hours in the boxes. Use the plus sign to add multiple time
                                        slots in one day. This will help you accommodate breaks in your day.
                                    </div>

                                    <ThemeProvider theme={materialTheme}>
                                        {Object.keys(daysOfTheWeek).map((index) => {
                                                return <DailyHours
                                                    key={`daily_hours_${index}`}
                                                    classes={classes}
                                                    day={{timeSlots: daysOfTheWeek[index], index: index}}
                                                    days={daysOfTheWeek}
                                                    setDays={setDaysOfTheWeek}
                                                />
                                            }
                                        )}
                                    </ThemeProvider>
                                </Grid>

                                <Grid container className={`${classes.panel} right`}>
                                    <div className={classes.panelTitle}>Add unavailable dates and times</div>
                                    <div className={classes.panelDescription}>Add dates and times where you are
                                        unavailable during your working hours.
                                    </div>

                                    <Button color='rose' className={`${classes.panelButton} outlined`}
                                            onClick={() => setDateSelectorOpen(true)}>Add a date override</Button>

                                    {overrideDates?.map((override, index) => {
                                        return (
                                            <div key={`override_item_${index}`} className={classes.overrideItem}>
                                                <div
                                                    className={`${classes.padded}`}>{moment(override?.date).format('dddd[\n]DD MMM YYYY')}</div>
                                                <div className={classes.timeSlot}>{override.start} <Lock/></div>
                                                <div className={classes.timeSlot}>{override.end} <Lock/></div>
                                                <CustomIconButton icon={<Trash/>} onClick={() => {
                                                    setDeleteOverrides([...deleteOverrides, overrideDates[index]])
                                                    overrideDates.splice(index, 1)
                                                    setOverrideDates([...overrideDates])
                                                }}/>
                                            </div>
                                        )
                                    })}

                                    <Button className={`${classes.panelButton} anchorBottom`} color='rose'
                                            disabled={!unsavedData || calendarLoading} onClick={() => {
                                        Object.entries(daysOfTheWeek).map(([index, day]) => day.map(timeSlot => {
                                            delete timeSlot.unsaved
                                        }))
                                        dispatch(setWorkingHours({general: daysOfTheWeek, overrides: overrideDates, overridesToDelete: deleteOverrides}))
                                        dispatch(saveWorkingHours({general: daysOfTheWeek, overrides: overrideDates, overridesToDelete: deleteOverrides}))
                                        setUnsavedData(false)
                                    }}>Save</Button>
                                </Grid>
                            </Grid>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}