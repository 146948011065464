import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/bannerStyle.js";

const useStyles = makeStyles(styles);

export default function Banner(props) {
  const { color, show, children } = props;
  const classes = useStyles();
  return (
    <span className={classes.banner + " " + classes[color] + " " + (show ? 'show' : '')}>{children}</span>
  );
}

Banner.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  children: PropTypes.node
};