import React from "react";

const Settings = () => {
    return (
      <div>
          Please select a setting from the menu to change settings
      </div>
    );
};

export default Settings;
