import React, { useEffect } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";

import { setAlert } from "../../redux/actions/bookings";

import {
    clearPracticeInformationForm, fetchPracticeDetails,
    savePracticeInformation,
    setPracticeInformationFormField
} from "../../redux/actions/settings";
import InfoIcon from "@material-ui/icons/Info";

// @material-ui/icons

const useStyles = makeStyles(styles);
export default function ExtendedForms() {

    const dispatch = useDispatch();
    const alert = useSelector(state => state.bookings.alert);
    const formFields = useSelector(state => state.settings.practiceInformationFormFields);
    const practitionerPractices = useSelector(state => state.telehealth.practitionerPractices);
    const practitionerPracticeDetails = useSelector(state => state.settings.selectedPracticeInformation);
    const networkActions = useSelector(state => state.settings.networkRequests);

    const isNetworkLoading = (keysToCheck) => {
        return keysToCheck.some((key) => {
            return !!networkActions[key]
        })
    };

    const filteredPractices = () => {
        return practitionerPractices
    };

    const isFilled = (text) => {
        return !!text && text.length > 0
    };

    const isNumeric = (value) => {
        let pattern = /^\d+$/;
        return pattern.test(value);
    }

    const isFormInvalid = () => {
        if (isNetworkLoading(['savePracticeInformation']))
            return true
        for (const field in formFields) {
            if (!formFields[field])
                return true

            if (typeof formFields[field] === "string"){
                let fieldValid = isFilled(formFields[field]);
                if (!fieldValid)
                    return true
            }

            if (["vat_number"].indexOf(field) > -1){
                if (!isNumeric(formFields[field]))
                    return true
            }
        }
        return false;
    };

    const isInvalid = isFormInvalid();

    useEffect(() => {
        dispatch(clearPracticeInformationForm());
        return (()=>{
            dispatch(clearPracticeInformationForm());
        })
    }, []);


    const practitionerSelectionFilterOptions = createFilterOptions({
        stringify: p => `${p.site.name} ${p.name}`
    });

    const handlePracticeSelected = (event, newValue) => {
        dispatch(setPracticeInformationFormField("selectedPractice", newValue));
        if (newValue){
            dispatch(fetchPracticeDetails(newValue['id']))
        }
    };

    const classes = useStyles();

    const handleSubmit = () => {
        const payload = {
            vat_number: formFields["vat_number"],
            practice_id: formFields["selectedPractice"]["id"]
        };

        dispatch(savePracticeInformation(payload));
    };

    const renderAlert = () => {
        return (
            <SweetAlert
                success={alert.type === "success"}
                warning={alert.type === "warning"}
                info={alert.type === "info"}
                style={{ display: "block", marginTop: "-100px" }}
                title={alert.title || ""}
                onConfirm={() => {
                    dispatch(setAlert({ show: false }));
                }}
                onCancel={() => dispatch(setAlert({ show: false }))}
                confirmBtnCssClass={classes.button + " " + (alert.type === "success" ? classes.success : classes.warning)}
                show={!!alert.show}
            >
                  {alert.message}
            </SweetAlert>
        );
    };

    const renderPracticeInformationFields = () => {
        return (
          <React.Fragment>

              <CustomInput
                labelText="VAT Registration Number"
                id="vat_number"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    autoComplete: "vat_number",
                    value: formFields["vat_number"],
                    onChange: (e) => dispatch(setPracticeInformationFormField("vat_number", e.target.value))
                }}
              />

              <Button color="rose" onClick={handleSubmit}
                      disabled={isInvalid}>Save Practice Information</Button>
          </React.Fragment>
        );
    };

    const renderForm = () => {

        return (
          <form>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >

                  <Autocomplete
                    options={filteredPractices()}
                    getOptionLabel={(p) => p ? `[${p.site.name} - ${p.name}] ${p.name}` : ""}
                    value={formFields["selectedPractice"]}
                    onChange={handlePracticeSelected}
                    openOnFocus={true}
                    groupBy={(p) => `${p.site.name} - ${p.name}`}
                    filterOptions={practitionerSelectionFilterOptions}
                    renderOption={(o) => <p>{o.name}</p>}
                    renderInput={(params) => <TextField
                      label="Select a Practice"
                      {...params}
                      inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // Avoid Auto Complete
                      }}
                    />}
                  />
                  {
                      isNetworkLoading(['getPractitionerPractices', 'fetchPracticeInformation'])
                        ? "Loading..." :
                    (practitionerPracticeDetails && formFields['selectedPractice']  ? renderPracticeInformationFields() : null)
                  }
              </FormControl>


          </form>
        );
    };

    return (
      <div>
          {renderAlert()}
          <GridContainer>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <InfoIcon/>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Update your practice information</h4>
                  </CardHeader>
                  <CardBody>
                      {renderForm()}
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    );
}
