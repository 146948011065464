import moment from "moment/moment";

import {
    SET_ALERT,
    SET_BOOKINGS,
    SET_BOOKINGS_LOADING,
    SET_BOOKING_FORM_FIELD,
    CLEAR_BOOKINGS_FORM, SET_BOOKINGS_REQUEST_TIMESTAMP, SET_BOOKING, SET_SURVEY_QUESTIONS
} from "../actions/bookings";
import { AUTH_LOGOUT_USER } from "../actions/auth";

const getRoundedTime = () => {
    const start = moment();
    const remainder = 5 - (start.minute() % 5);

    const dateTime = moment(start).add(remainder, "minutes").seconds(0);
    return dateTime;
};

const defaultBookingsForm = {
    paymentMethod: "private",
    selectedPractitioner: null,
    datetime: getRoundedTime(),
    name: "",
    patientNumber: "",
    email: "",
    contactNumber: "",
    autoPayment: false,
    billingAmount: 5,
    billingAmountMasked: "R 5.00",
    tariffCode: "",
    bookingType: "individual consult",
    groupName: ""
};

const initState = {
    bookingsList: [],
    bookingsLoading: false,
    alert: { show: false },
    bookingFormFields: { ...defaultBookingsForm },
    bookingsRequestTimestamp: null,
    groupBooking: null,
    surveyQuestions: []
};

export const bookingsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_BOOKINGS:
            if (action.payload.bookings.length > 0 && action.timestamp !== state.bookingsRequestTimestamp)
                return {...state}
            return { ...state, bookingsList: action.payload.bookings };
        case SET_BOOKINGS_LOADING:
            let startRequest = !!action.payload;
            let value;
            if (startRequest){
                if (!state.bookingsLoading)
                    value = 1
                else
                    value = state.bookingsLoading + 1
            }
            else{
                value = state.bookingsLoading - 1;
                if (value < 1)
                    value = false
            }
            return { ...state, bookingsLoading: value };
        case SET_ALERT:
            return { ...state, alert: action.payload };
        case SET_BOOKINGS_REQUEST_TIMESTAMP:
            return { ...state, bookingsRequestTimestamp: action.payload };
        case SET_BOOKING_FORM_FIELD:
            return {
                ...state,
                bookingFormFields: { ...state.bookingFormFields, [action.payload.field]: action.payload.value }
            };
        case CLEAR_BOOKINGS_FORM:
            return { ...state, bookingFormFields: { ...defaultBookingsForm } };
        case AUTH_LOGOUT_USER:
            return { ...state, bookingsList: [], bookingsLoading: false };
        case SET_BOOKING:
            return {...state, groupBooking: {
                    facilitator: action.payload.filter((item)=>{ return item.booking_type === 'group consult facilitator'})[0],
                    participants: action.payload.filter((item)=>{ return item.booking_type === 'group consult participant'})
                }};
        case SET_SURVEY_QUESTIONS:
            return {...state, surveyQuestions: [...action.payload]}
        default:
            return state;
    }
};
