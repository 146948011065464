import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {createBrowserHistory} from "history";

import routerMiddleware from './middleware/core/router';
import api from './middleware/core/api';
import storage from './middleware/core/storage';

import auth from './middleware/feature/auth';
import bookings from './middleware/feature/bookings';
import telehealth from './middleware/feature/telehealth';
import settings from './middleware/feature/settings';
import fileUpload from './middleware/feature/fileUpload';

import {authReducer} from './reducers/auth.reducer';
import {bookingsReducer} from './reducers/bookings.reducer';
import {telehealthReducer} from './reducers/telehealth.reducer';
import { settingsReducer } from './reducers/settings.reducer';
import {fileUploadReducer} from "./reducers/fileUpload.reducer";

const rootReducer = combineReducers({
	auth: authReducer,
	bookings: bookingsReducer,
	settings: settingsReducer,
	telehealth: telehealthReducer,
	fileUpload: fileUploadReducer
});

export const history = createBrowserHistory();

const featureMiddleware = [auth, bookings, telehealth, settings, fileUpload];

const coreMiddleware = [routerMiddleware(history), api, storage];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(...featureMiddleware, ...coreMiddleware)
);

export const store = createStore(rootReducer, {}, enhancer);

window.store = store;
