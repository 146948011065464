import {
    CLEAR_UPLOAD_PROGRESS,
    SET_UPLOAD_PROGRESS
} from "../actions/fileUpload";

const initState = {
};

export const fileUploadReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_UPLOAD_PROGRESS:
            return {...state, [action.md5]: action.payload}
        case CLEAR_UPLOAD_PROGRESS:
            return { ...initState }
        default:
            return state;
    }
};