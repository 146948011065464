/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

export default function Footer(props) {
  return null;
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
