import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PatientFormFields from "./PatientFormFields";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import { useDispatch, useSelector } from "react-redux";
import { setBookingFormField } from "../../../redux/actions/bookings";

const isFormInvalid = (formFields, participants) => {
    if (formFields["name"] === "" || formFields["email"] === "" || formFields["contactNumber"] === "")
        return true;

    return formFields["paymentMethod"] === "private" && formFields["autoPayment"] && (formFields["tariffCode"] === "" || formFields["billingAmount"] < 5);
};

const AddParticipantDialog = ({open, formFields, onClose, onSubmit, bookingId}) => {
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const dispatch = useDispatch();
    const groupBooking = useSelector(state => state.bookings.groupBooking);
    const [participantNames, setParticipantNames] = useState([]);
    const duplicateParticipant = formFields && participantNames.indexOf(formFields["name"]) > -1;
    const isInvalid = isFormInvalid(formFields, participantNames) || duplicateParticipant;


    useEffect(()=>{
        if (!groupBooking) return;

        let tempParticipantNames = [];

        if (groupBooking.facilitator){
            tempParticipantNames.push(groupBooking.facilitator.practitioner_name)
        }
        if (groupBooking.participants){
            tempParticipantNames = [...tempParticipantNames, ...groupBooking.participants.map((participant)=>participant.patient_name)]
        }
        setParticipantNames(tempParticipantNames)

    },[groupBooking])

    useEffect(()=>{
        if (open) dispatch(setBookingFormField("group_booking_id", bookingId));
    }, [open])

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter Patient Details</DialogTitle>
        <DialogContent>
          <form>
              <PatientFormFields formFields={formFields} duplicateParticipant={duplicateParticipant}/>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="gray" onClick={onClose}>Cancel</Button>

          <Button color="rose" onClick={onSubmit}
                      disabled={!!bookingsLoading || isInvalid}>Add Patient</Button>
        </DialogActions>
      </Dialog>
    );
};

export default AddParticipantDialog;