import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { setAlert } from "../../redux/actions/bookings";
import { useDispatch, useSelector } from "react-redux";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    ...buttonStyle
};

const useStyles = makeStyles(styles);

const RenderAlert = () => {
    const alert = useSelector(state => state.bookings.alert);
    const dispatch = useDispatch();
    const classes = useStyles();

    if (!alert.show) return null;

    return (
      <SweetAlert
        success={alert.type === "success"}
        warning={alert.type === "warning"}
        style={{ display: "block", marginTop: "-100px" }}
        title={alert.title}
        onConfirm={() => {
            dispatch(setAlert({ show: false }));
            if (alert.confirmAction){
                dispatch(alert.confirmAction);
            }
        }}
        onCancel={() => dispatch(setAlert({ show: false }))}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
          {alert.message}
      </SweetAlert>
    );
};

export default RenderAlert;
