import React from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import login from "assets/img/login.jpeg";

const useStyles = makeStyles(styles);

const Auth = (props) => {
    const { ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const classes = useStyles();
    React.useEffect(() => {
        document.body.style.overflow = "unset";
        // Specify how to clean up after this effect:
        return function cleanup() {
        };
    });
    const getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/auth") {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
            } else {
                return null;
            }
        });
    };

    const getActiveRoute = routes => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                  window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    return (
      <div>
          <div className={classes.wrapper} ref={wrapper}>
              <div
                className={classes.fullPage}
                style={{ backgroundImage: "url(" + login + ")" }}
              >
                  <Switch>
                      {getRoutes(routes)}
                      <Redirect from="/auth" to="/auth/login"/>
                  </Switch>
              </div>
          </div>
      </div>
    );
};

export default Auth;