export const GET_FILE_UPLOAD_URL = "GET_FILE_UPLOAD_URL";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const CLEAR_UPLOAD_PROGRESS = "CLEAR_UPLOAD_PROGRESS";

export const clearUploadProgress = () => ({type:CLEAR_UPLOAD_PROGRESS});
export const setFileUploadProgress = (md5, progress) => ({type: SET_UPLOAD_PROGRESS, payload: progress, md5});
export const getFileUploadUrl = (md5, fileName, file, bookingID) => ({
    type: GET_FILE_UPLOAD_URL,
    payload: { fileName, bookingID },
    file,
    md5
});
