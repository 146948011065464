import React from "react";
// react component for creating dynamic tables
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import moment from "moment";

import { fetchBookings, performBookingAction, setBookings } from "redux/actions/bookings";

import Loader from "react-loader-spinner";
import { push } from "../../redux/middleware/core/router";
import RenderAlert from "./renderAlert";
import BookingsTable from "./BookingsTable";
import { bookingActions, getPaymentStatus } from "./bookingUtils";


const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);


export default function ReactTables(props) {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setBookings({ bookings: [] }));
        dispatch(fetchBookings());
        return (() => {
            dispatch(setBookings({ bookings: [] }));
        });
    }, []);

    const bookings = useSelector(state => state.bookings.bookingsList);
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);

    const doAction = (bookingId, action) => {
        if (action === "edit-group-booking") {
            dispatch(push(`/admin/edit_booking/${bookingId}`));
        } else {
            dispatch(performBookingAction(bookingId, action));
        }
    };

    React.useEffect(() => {
        setData(
          bookings.map((booking, key) => (
            {
                id: booking.id,
                practitioner_name: booking.practitioner_name,
                patient_name: booking.patient_name,
                patient_email: booking.patient_email,
                patient_contact_number: booking.patient_contact_number,
                date: moment(booking.datetime).format("YYYY-MM-DD"),
                time: moment(booking.datetime).format("HH:mm"),
                relativeTime: moment(booking.datetime).fromNow(),
                date_time: moment(booking.datetime).format("YYYY-MM-DD HH:mm"),
                payment_status: getPaymentStatus(booking),
                booking_type: booking.booking_type,
                actions: (
                  <div className="actions-right">

                      <CustomDropdown
                        hoverColor="info"
                        buttonText="Actions"
                        onClick={(e) => doAction(e.props.id, e.props.actionid)}
                        buttonProps={{
                            style: { marginBottom: "0" },
                            color: "rose"
                        }}
                        dropdownHeader="Actions"
                        dropdownList={bookingActions(booking)}
                      />
                  </div>
                )
            }
          ))
        );
    }, [bookings]);

    const [data, setData] = React.useState([]);

    const classes = useStyles();
    return (
      <GridContainer>
          <RenderAlert/>
          <GridItem xs={12}>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <Assignment/>
                      </CardIcon>
                      <div style={{ display: "flex", alignItems: "center", margin: 15 }}>
                          <h4 className={classes.cardIconTitle} style={{ margin: 5 }}>Bookings</h4>
                          {
                              bookingsLoading && <Loader
                                type="ThreeDots"
                                color="#00acc1"
                                height={100}
                                width={100}
                                timeout={15000} //15 secs
                                style={{ paddingLeft: "15" }}
                              />
                          }

                      </div>
                  </CardHeader>
                  <CardBody>
                      <BookingsTable data={data} columnsToHide={["Patient Number"]}/>
                  </CardBody>
              </Card>
          </GridItem>
      </GridContainer>
    );
}
