import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import FileUploadScene from "./file_upload";
import { useDispatch, useSelector } from "react-redux";
import { isUploadComplete } from "./utils";
import { clearUploadProgress } from "../../../redux/actions/fileUpload";

const AddFileDialog = ({ open, onClose, onSubmit, filePrefix }) => {
    const [activeFiles, setActiveFiles] = useState([]);
    const uploadStatus = useSelector(state => state.fileUpload);
    const dispatch = useDispatch();

    const uploadsCompleted = () => {
        return isUploadComplete(activeFiles, uploadStatus);
    };

    const onCloseDialog = (action) => {
        if (action === 'submit')
            onSubmit(activeFiles);
        dispatch(clearUploadProgress());
        onClose()
    };

    return (
      <Dialog
        open={open}
        onClose={() => onCloseDialog('cancel')}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Select a File</DialogTitle>
          <DialogContent style={{ height: "50vh" }}>
              <FileUploadScene
                filePrefix={filePrefix}
                uploadFileType={"image/jpeg, image/png, application/pdf"}
                onActiveFile={(files) => {
                    setActiveFiles(files);
                }}
                maxFileSize={5000000}
                allowMultipleFiles={true}
                maxFiles={5}
              />
          </DialogContent>
          <DialogActions>
              <Button color="gray" onClick={()=>onCloseDialog('cancel')}>Cancel</Button>

              <Button color="rose" onClick={() =>onCloseDialog('submit')}
                      disabled={!uploadsCompleted() || activeFiles.length < 1}>Add Attachments to Booking</Button>
          </DialogActions>
      </Dialog>
    );
};

export default AddFileDialog;