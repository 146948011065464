export const BANK_NAMES = [
    "Absa",
    "African Bank",
    "Al Baraka",
    "Bank of China",
    "Bank of Taiwan",
    "Bidvest Bank",
    "BNP Paribas",
    "BofA Securities",
    "Capitec Bank",
    "China Construction Bank",
    "Citibank",
    "Deutsche Bank",
    "Discovery Bank",
    "FinBond Mutual Bank",
    "FirstRand",
    "GBS Mutual Bank",
    "Goldman Sachs",
    "Grindrod Bank",
    "Grobank",
    "Habib Overseas Bank",
    "HBZ Bank",
    "HSBC Bank",
    "ICICI Bank",
    "Investec",
    "Ithala",
    "JPMorgan Chase",
    "Mercantile Bank",
    "Nedbank",
    "Postbank",
    "Sasfin Bank",
    "Societe Generale",
    "Standard Bank",
    "Standard Chartered",
    "State Bank of India",
    "TymeBank",
    "UBank Ltd"
];

export const awaitingVerificationAlertMessage = "Please wait whilst we verify the bank account details provided"
export const savedPendingVerification = "Your account details will be validated before payouts can be made to your account"
